import * as PIXI from "pixi.js";
export default class FishContainer extends PIXI.Container {
    destroy(options) {
        this.onDestroy();
        super.destroy(options);
    }
    resize(width, height) {
        for (let i = 0; i < this.children.length; i++) {
            if (this.children[i].resize) {
                this.children[i].resize(width, height);
            }
        }
        this.boundsArea = new PIXI.Rectangle(0, 0, width, height);
        this.onResize(width, height);
    }
    onDestroy() { }
    onResize(width, height) { }
}
