import * as PIXI from "pixi.js";
import FishColorMapFilter from "../Utils/Filter/FishColorMapFilter";
import FishColorCurve from "../Utils/Curve/FishColorCurve";
export default class ImageProcessingCanvas {
    constructor() {
        this.canvas = document.createElement("canvas");
        this.colorMapFilter = new FishColorMapFilter();
    }
    static getInstance() {
        if (!ImageProcessingCanvas.instance) {
            ImageProcessingCanvas.instance = new ImageProcessingCanvas();
        }
        return ImageProcessingCanvas.instance;
    }
    getImageData(imageUrl) {
        let that = this;
        return new Promise((resolve, reject) => {
            let image = new Image();
            image.onload = () => {
                try {
                    that.canvas.width = image.width;
                    that.canvas.height = image.height;
                    let context = that.canvas.getContext("2d", { willReadFrequently: true });
                    context.drawImage(image, 0, 0);
                    let imageData = context.getImageData(0, 0, image.width, image.height);
                    resolve(imageData);
                }
                catch (e) {
                    reject(e);
                }
            };
            image.setAttribute('crossOrigin', '');
            image.onerror = (e) => {
                reject(e);
            };
            image.src = imageUrl;
        });
    }
    imageNormalization(inputImages, canvas, isChangeCurve) {
        console.time("time0");
        let tempCanvas = document.createElement("canvas");
        let canvasWidth = canvas.stage.width;
        let canvasHeight = canvas.stage.height;
        tempCanvas.width = canvasWidth;
        tempCanvas.height = canvasHeight;
        let tempContext = tempCanvas.getContext("2d", { willReadFrequently: true });
        for (let i = 0; i < inputImages.length; i++) {
            let imageData = inputImages[i].orginalImage;
            this.canvas.width = imageData.width;
            this.canvas.height = imageData.height;
            let context = this.canvas.getContext("2d", { willReadFrequently: true });
            if (canvasWidth / canvasHeight > imageData.width / imageData.height) {
                let width = canvasHeight * imageData.width / imageData.height;
                let height = canvasHeight;
                let startX = (canvasWidth - width) / 2;
                let startY = 0;
                context.putImageData(imageData, 0, 0);
                tempContext.drawImage(this.canvas, startX, startY, width, height);
            }
            else {
                let width = canvasWidth;
                let height = canvasWidth * imageData.height / imageData.width;
                let startX = 0;
                let startY = (canvasHeight - height) / 2;
                context.putImageData(imageData, 0, 0);
                tempContext.drawImage(this.canvas, startX, startY, width, height);
            }
            inputImages[i].showImage = tempContext.getImageData(0, 0, canvasWidth, canvasHeight);
            if (!isChangeCurve) {
                inputImages[i].manualProcessingShowValue = inputImages[i].manualProcessingSavedValue;
            }
            console.timeEnd("time0");
            this.imageProcessing(inputImages[i], canvas);
        }
    }
    imageFusion(inputImageDatas, canvasWidth, canvasHeight) {
        this.canvas.width = canvasWidth;
        this.canvas.height = canvasHeight;
        let context = this.canvas.getContext("2d", { willReadFrequently: true });
        context.clearRect(0, 0, canvasWidth, canvasHeight);
        let imageData = context.createImageData(canvasWidth, canvasHeight);
        for (let i = 0; i < canvasWidth; i++) {
            for (let j = 0; j < canvasHeight; j++) {
                let r = 0;
                let g = 0;
                let b = 0;
                for (let k = 0; k < inputImageDatas.length; k++) {
                    let item = inputImageDatas[k];
                    r = Math.max(r, item.data[j * canvasWidth * 4 + i * 4]);
                    g = Math.max(g, item.data[j * canvasWidth * 4 + i * 4 + 1]);
                    b = Math.max(b, item.data[j * canvasWidth * 4 + i * 4 + 2]);
                }
                imageData.data[j * canvasWidth * 4 + i * 4] = r;
                imageData.data[j * canvasWidth * 4 + i * 4 + 1] = g;
                imageData.data[j * canvasWidth * 4 + i * 4 + 2] = b;
                imageData.data[j * canvasWidth * 4 + i * 4 + 3] = 255;
            }
        }
        return imageData;
    }
    eraseImage(image, pointPos, eraseSize, canvasWidth, canvasHeight) {
        let point = new PIXI.Point(pointPos.x, pointPos.y);
        let square = Math.pow(eraseSize / 2, 2);
        let startPos = new PIXI.Point(point.x - eraseSize / 2, point.y - eraseSize / 2);
        if (startPos.x < 0) {
            startPos.x = 0;
        }
        if (startPos.y < 0) {
            startPos.y = 0;
        }
        if (startPos.x >= canvasWidth) {
            startPos.x = canvasWidth - 1;
        }
        if (startPos.y >= canvasHeight) {
            startPos.y = canvasHeight - 1;
        }
        let endPos = new PIXI.Point(point.x + eraseSize / 2, point.y + eraseSize / 2);
        if (endPos.x < 0) {
            endPos.x = 0;
        }
        if (endPos.y < 0) {
            endPos.y = 0;
        }
        if (endPos.x >= canvasWidth) {
            endPos.x = canvasWidth - 1;
        }
        if (endPos.y >= canvasHeight) {
            endPos.y = canvasHeight - 1;
        }
        startPos.x = Math.round(startPos.x);
        startPos.y = Math.round(startPos.y);
        endPos.x = Math.round(endPos.x);
        endPos.y = Math.round(endPos.y);
        for (let i = startPos.x; i < endPos.x; i++) {
            for (let j = startPos.y; j < endPos.y; j++) {
                if (Math.pow(i - point.x, 2) + Math.pow(j - point.y, 2) < square) {
                    image.showImage.data[j * image.showImage.width * 4 + i * 4] = 0;
                    image.showImage.data[j * image.showImage.width * 4 + i * 4 + 1] = 0;
                    image.showImage.data[j * image.showImage.width * 4 + i * 4 + 2] = 0;
                }
            }
        }
        let width = 0;
        let height = 0;
        let startX = 0;
        let startY = 0;
        if (canvasWidth / canvasHeight > image.orginalImage.width / image.orginalImage.height) {
            width = canvasHeight * image.orginalImage.width / image.orginalImage.height;
            height = canvasHeight;
            startX = (canvasWidth - width) / 2;
            startY = 0;
        }
        else {
            width = canvasWidth;
            height = canvasWidth * image.orginalImage.height / image.orginalImage.width;
            startX = 0;
            startY = (canvasHeight - height) / 2;
        }
        if (point.x < startX || point.x >= startX + width || point.y < startY || point.y >= startY + height) {
            return;
        }
        point.x -= startX;
        point.y -= startY;
        let size = image.orginalImage.width / width * eraseSize;
        point.x *= (image.orginalImage.width / width);
        point.y *= (image.orginalImage.height / height);
        square = Math.pow(size / 2, 2);
        startPos = new PIXI.Point(point.x - size / 2, point.y - size / 2);
        if (startPos.x < 0) {
            startPos.x = 0;
        }
        if (startPos.y < 0) {
            startPos.y = 0;
        }
        if (startPos.x >= image.orginalImage.width) {
            startPos.x = image.orginalImage.width - 1;
        }
        if (startPos.y >= image.orginalImage.height) {
            startPos.y = image.orginalImage.height - 1;
        }
        endPos = new PIXI.Point(point.x + size / 2, point.y + size / 2);
        if (endPos.x < 0) {
            endPos.x = 0;
        }
        if (endPos.y < 0) {
            endPos.y = 0;
        }
        if (endPos.x >= image.orginalImage.width) {
            endPos.x = image.orginalImage.width - 1;
        }
        if (endPos.y >= image.orginalImage.height) {
            endPos.y = image.orginalImage.height - 1;
        }
        startPos.x = Math.round(startPos.x);
        startPos.y = Math.round(startPos.y);
        endPos.x = Math.round(endPos.x);
        endPos.y = Math.round(endPos.y);
        for (let i = startPos.x; i < endPos.x; i++) {
            for (let j = startPos.y; j < endPos.y; j++) {
                if (Math.pow(i - point.x, 2) + Math.pow(j - point.y, 2) < square) {
                    image.orginalImage.data[j * image.orginalImage.width * 4 + i * 4] = 0;
                    image.orginalImage.data[j * image.orginalImage.width * 4 + i * 4 + 1] = 0;
                    image.orginalImage.data[j * image.orginalImage.width * 4 + i * 4 + 2] = 0;
                }
            }
        }
    }
    imageProcessing(image, canvas) {
        console.time("time1");
        if (image.autoProcessingMaxValue >= 0 && image.autoProcessingMinValue >= 0) {
            if (image.autoProcessingMaxValue > 255) {
                image.autoProcessingMaxValue = 255;
            }
            if (image.autoProcessingMinValue > 255) {
                image.autoProcessingMinValue = 255;
            }
            if (image.autoProcessingMinValue > image.autoProcessingMaxValue) {
                let temp = image.autoProcessingMinValue;
                image.autoProcessingMinValue = image.autoProcessingMaxValue;
                image.autoProcessingMaxValue = temp;
            }
            let min = image.autoProcessingMinValue;
            let max = image.autoProcessingMaxValue;
            let width = image.showImage.width;
            let height = image.showImage.height;
            let data = image.showImage.data;
            for (let i = 0; i < width; i++) {
                for (let j = 0; j < height; j++) {
                    let r = data[j * width * 4 + i * 4];
                    let g = data[j * width * 4 + i * 4 + 1];
                    let b = data[j * width * 4 + i * 4 + 2];
                    if ((r < min && g < min && b < min) || (r > max || g > max || b > max)) {
                        data[j * width * 4 + i * 4] = 0;
                        data[j * width * 4 + i * 4 + 1] = 0;
                        data[j * width * 4 + i * 4 + 2] = 0;
                    }
                }
            }
        }
        console.timeEnd("time1");
        console.time("time2");
        if (image.manualProcessingShowValue) {
            let graphics = new PIXI.Graphics();
            graphics.boundsArea = new PIXI.Rectangle(0, 0, image.showImage.width, image.showImage.height);
            let data = new Uint8Array(image.showImage.data);
            let texture = PIXI.Texture.from({ resource: data, width: image.showImage.width, height: image.showImage.height });
            graphics.texture(texture);
            let curve = new FishColorCurve();
            curve.setCurveData(JSON.parse(image.manualProcessingShowValue));
            this.colorMapFilter.setColorMap(curve.getRGBCurve(), curve.getRCurve(), curve.getGCurve(), curve.getBCurve());
            graphics.filters = [this.colorMapFilter];
            let processingTexture = canvas.pixi.renderer.extract.texture({ target: graphics, resolution: 1 });
            let imageData = canvas.pixi.renderer.extract.pixels(processingTexture);
            graphics.destroy(true);
            texture.destroy(true);
            processingTexture.destroy(true);
            image.showImage = new ImageData(imageData.pixels, imageData.width, imageData.height);
            console.log(imageData.width, imageData.height);
        }
        console.timeEnd("time2");
    }
}
