import * as PIXI from "pixi.js";
class ColorMapFilter extends PIXI.Filter {
    //#endregion
    constructor() {
        let fragment = ColorMapFilter.fragment;
        let vertex = ColorMapFilter.vertex;
        super({
            glProgram: new PIXI.GlProgram({
                fragment,
                vertex
            }),
            resources: {
                timeUniforms: {
                    uRMap: { value: ColorMapFilter.defaultCurve, type: "f32", size: 256 },
                    uGMap: { value: ColorMapFilter.defaultCurve, type: "f32", size: 256 },
                    uBMap: { value: ColorMapFilter.defaultCurve, type: "f32", size: 256 }
                },
            },
        });
        this.uRMap = new Array(256);
        this.uGMap = new Array(256);
        this.uBMap = new Array(256);
    }
    setColorMap(map, rMap, gMap, bMap) {
        for (let i = 0; i < map.length; i++) {
            this.uRMap[i] = map[Math.round(rMap[i])];
            this.uGMap[i] = map[Math.round(gMap[i])];
            this.uBMap[i] = map[Math.round(bMap[i])];
        }
        //console.log(map);
        this.resources.timeUniforms.uniforms.uRMap = this.uRMap;
        this.resources.timeUniforms.uniforms.uGMap = this.uGMap;
        this.resources.timeUniforms.uniforms.uBMap = this.uBMap;
    }
    resetMap() {
        this.resources.timeUniforms.uniforms.uRMap = ColorMapFilter.defaultCurve;
        this.resources.timeUniforms.uniforms.uGMap = ColorMapFilter.defaultCurve;
        this.resources.timeUniforms.uniforms.uBMap = ColorMapFilter.defaultCurve;
    }
}
//#region 静态数据
ColorMapFilter.defaultCurve = new Array(256).fill(0).map((_, i) => { return i; });
ColorMapFilter.fragment = `in vec2 vTextureCoord;
in vec4 vColor;

uniform sampler2D uTexture;
uniform float uRMap[256];
uniform float uGMap[256];
uniform float uBMap[256];

void main(void)
{
    vec2 uvs = vTextureCoord.xy;

    vec4 fg = texture2D(uTexture, vTextureCoord);
    int rIndex = int(floor(fg.r * 255.0));
    int gIndex = int(floor(fg.g * 255.0));
    int bIndex = int(floor(fg.b * 255.0));
    float r = 0.0;
    float g = 0.0;
    float b = 0.0;
    for (int i = 0; i < 256; i++) {
        if (i == rIndex) {
            r = uRMap[i] / 255.0;
        }
        if (i == gIndex) {
            g = uGMap[i] / 255.0;
        }
        if (i == bIndex) {
            b = uBMap[i] / 255.0;
        }
    }
    fg.r = r;
    fg.b = b;
    fg.g = g;
    gl_FragColor = fg;

}`;
ColorMapFilter.vertex = `in vec2 aPosition;
out vec2 vTextureCoord;

uniform vec4 uInputSize;
uniform vec4 uOutputFrame;
uniform vec4 uOutputTexture;

vec4 filterVertexPosition( void )
{
    vec2 position = aPosition * uOutputFrame.zw + uOutputFrame.xy;
    
    position.x = position.x * (2.0 / uOutputTexture.x) - 1.0;
    position.y = position.y * (2.0*uOutputTexture.z / uOutputTexture.y) - uOutputTexture.z;

    return vec4(position, 0.0, 1.0);
}

vec2 filterTextureCoord( void )
{
    return aPosition * (uOutputFrame.zw * uInputSize.zw);
}

void main(void)
{
    gl_Position = filterVertexPosition();
    vTextureCoord = filterTextureCoord();
}`;
export default ColorMapFilter;
