import * as PIXI from "pixi.js";
export default class ScalePanel extends PIXI.Container {
    constructor(canvas, backgroundColor, options) {
        super(options);
        this.mousePosition = new PIXI.Point();
        this.ratioPosX = 0;
        this.ratioPosY = 0;
        this._canMove = true;
        this._maxScale = 2;
        this.backScale = 1;
        this._canvas = canvas;
        this._pixi = canvas.pixi;
        this.backgroundColor = backgroundColor;
        this.boundsArea = new PIXI.Rectangle(0, 0, options.width, options.height);
        this.bg = new PIXI.Graphics().rect(0, 0, options.width, options.height).fill({ color: backgroundColor });
        this.addChild(this.bg);
        this.hitArea = this.boundsArea;
        this.on("wheel", (event) => {
            if (!this.canvas.isMainCanvas) {
                return;
            }
            event.stopImmediatePropagation();
            let oldWidth = this.width;
            let oldHeight = this.height;
            if (event.deltaY < 0 && this.scale.x < this._maxScale) {
                this.scale = this.scale.x + 0.1;
            }
            else if (event.deltaY > 0 && this.scale.x > 1) {
                this.scale = this.scale.x - 0.1;
            }
            let newWidth = this.width;
            let newHeight = this.height;
            this.position.x = (this.position.x - event.global.x) * newWidth / oldWidth + event.global.x;
            this.position.y = (this.position.y - event.global.y) * newHeight / oldHeight + event.global.y;
            this.checkPosition();
            this.onScaleChange();
        });
        this.backScale = this.scale.x;
        let isMouseIn = false;
        this.on("mousedown", (event) => {
            if (!this.canvas.isMainCanvas) {
                return;
            }
            this.mousePosition.set(event.screenX, event.screenY);
            isMouseIn = true;
        });
        this.on("mousemove", (event) => {
            if (!isMouseIn || !this.canMove || !this.canvas.isMainCanvas) {
                return;
            }
            console.log(event.target == this);
            this.position.x += (event.screenX - this.mousePosition.x);
            this.position.y += (event.screenY - this.mousePosition.y);
            this.mousePosition.set(event.screenX, event.screenY);
            this.checkPosition();
        });
        this.on("mouseup", (event) => {
            isMouseIn = false;
        });
        this.on("mouseout", (event) => {
            isMouseIn = false;
        });
    }
    get canvas() { return this._canvas; }
    get pixi() { return this._pixi; }
    get canMove() { return this._canMove; }
    set canMove(value) { this._canMove = value; }
    get maxScale() { return this._maxScale; }
    set maxScale(value) { this._maxScale = value; }
    resize(width = this._pixi.screen.width, height = this._pixi.screen.height) {
        this.boundsArea.width = width;
        this.boundsArea.height = height;
        this.position.x = this.ratioPosX * this.boundsArea.width * this.scale.x;
        this.position.y = this.ratioPosY * this.boundsArea.height * this.scale.y;
        this.bg.clear().rect(0, 0, this.boundsArea.width, this.boundsArea.height).fill({ color: this.backgroundColor });
        this.checkPosition();
    }
    zoomIn() {
        this.scale = this.scale.x + 0.2;
        if (this.scale.x > this._maxScale) {
            this.scale = this._maxScale;
        }
        this.checkPosition();
    }
    zoomOut() {
        this.scale = this.scale.x - 0.2;
        if (this.scale.x < 1) {
            this.scale = 1;
        }
        this.checkPosition();
    }
    setScaleChange(scaleChange) {
        this.scaleChange = scaleChange;
    }
    onScaleChange() {
        let scale = this.scale.x;
        if (scale != this.backScale) {
            this.scaleChange && this.scaleChange(scale);
        }
        this.backScale = scale;
    }
    focusIn(pos, scale = this._maxScale) {
        this.position.x -= pos.x;
        this.position.y -= pos.y;
        let width = this.canvas.pixi.screen.width / 2;
        let height = this.canvas.pixi.screen.height / 2;
        this.position.x += width;
        this.position.y += height;
        let oldWidth = this.width;
        let oldHeight = this.height;
        this.scale = scale;
        let newWidth = this.width;
        let newHeight = this.height;
        this.position.x = (this.position.x - width) * newWidth / oldWidth + width;
        this.position.y = (this.position.y - height) * newHeight / oldHeight + height;
        this.checkPosition();
    }
    checkScale() {
        let oldWidth = this.width;
        let oldHeight = this.height;
        this.scale = Math.max(1, Math.min(this._maxScale, this.scale.x));
        let newWidth = this.width;
        let newHeight = this.height;
        let x = this.pixi.stage.width / 2;
        let y = this.pixi.stage.height / 2;
        this.position.x = (this.position.x - x) * newWidth / oldWidth + x;
        this.position.y = (this.position.y - y) * newHeight / oldHeight + y;
        this.checkPosition();
    }
    checkPosition() {
        let width = this.width;
        let height = this.height;
        this.position.x = this.position.x > 0 ? 0 : this.position.x;
        this.position.x = this._pixi.screen.width - this.position.x > width ? this._pixi.screen.width - width : this.position.x;
        this.position.y = this.position.y > 0 ? 0 : this.position.y;
        this.position.y = this._pixi.screen.height - this.position.y > height ? this._pixi.screen.height - height : this.position.y;
        this.ratioPosX = this.position.x / width;
        this.ratioPosY = this.position.y / height;
    }
}
