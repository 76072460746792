import { Bounds, Point } from "pixi.js";
export default class FishUtilMath {
    /** 向量归一化 */
    static normalize(point) {
        let m = this.magnitude(point);
        return new Point(point.x / m, point.y / m);
    }
    /** 向量长度 */
    static magnitude(point) {
        return Math.sqrt(this.sqrMagnitude(point));
    }
    /** 向量长度的平方 */
    static sqrMagnitude(point) {
        return point.x * point.x + point.y * point.y;
    }
    /** 向量点积 */
    static dot(point1, point2) {
        return point1.x * point2.x + point1.y * point2.y;
    }
    /** 向量加 */
    static add(point1, point2) {
        return new Point(point1.x + point2.x, point1.y + point2.y);
    }
    /** 向量减 */
    static subtract(point1, point2) {
        return new Point(point1.x - point2.x, point1.y - point2.y);
    }
    /** 向量距离的平方 */
    static sqrDistance(point1, point2) {
        let dx = point1.x - point2.x;
        let dy = point1.y - point2.y;
        return dx * dx + dy * dy;
    }
    /** 向量距离 */
    static distance(point1, point2) {
        return Math.sqrt(this.sqrDistance(point1, point2));
    }
    /**全包含 */
    static getRandomIntNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    /**全不包含 */
    static getRandomFloatNumber(min, max) {
        return Math.random() * (max - min) + min;
    }
    /** 量量乘 */
    static multiply(point, num) {
        let newPoint = new Point();
        newPoint.x = point.x * num;
        newPoint.y = point.y * num;
        return newPoint;
    }
    static calculateBoundWidth(width, height, rotation) {
        if (rotation > Math.PI * 1.5 || (rotation > Math.PI / 2 && rotation < Math.PI)) {
            rotation = 2 * Math.PI - rotation;
        }
        let value = width * Math.sin(Math.PI / 2 - rotation) + height * Math.cos(Math.PI / 2 - rotation);
        value = Math.abs(value);
        return value;
    }
    static getPolyBounds(pointList) {
        let p = pointList[0];
        let minX = p.x;
        let minY = p.y;
        let maxX = p.x;
        let maxY = p.y;
        let x;
        let y;
        for (let i = 1; i < pointList.length; i++) {
            x = pointList[i].x;
            y = pointList[i].y;
            if (x > maxX) {
                maxX = x;
            }
            if (x < minX) {
                minX = x;
            }
            if (y > maxY) {
                maxY = y;
            }
            if (y < minY) {
                minY = y;
            }
        }
        let bound = new Bounds(minX, minY, maxX, maxY);
        return bound;
    }
    static linearInterpolation(point1, point2, value) {
        return new Point(point1.x + (point2.x - point1.x) * value, point1.y + (point2.y - point1.y) * value);
    }
}
