import * as PIXI from "pixi.js";
import { MarkMode } from "../../../../Canvas/MarkEditorCanvas";
import TextInput from "../../../Component/TextInput";
import UtilMath from "../../UtilMath";
export default class MarkText extends PIXI.Container {
    get inputWidth() {
        return this.left + this.right + this.overflowWidth;
    }
    get inputHeight() {
        return this.top + this.bottom + this.fontSize;
    }
    constructor(point) {
        super();
        this.bg = new PIXI.Graphics();
        this.covePanel = new PIXI.Graphics();
        this.textWidth = 1;
        this.textHeight = 20;
        /** 溢出宽度 */
        this.overflowWidth = 50;
        this.left = 5;
        this.right = 5;
        this.top = 5;
        this.bottom = 5;
        this.fontSize = 20;
        this.minInputHeight = 20;
        this.lineWidth = 2;
        this.fontColor = 0xff0000;
        this.isSelect = false;
        this.topOffice = 5;
        this.onCovePanelMousemove = (event) => {
            let pos = this.parent.toLocal(event.global);
            let delta = UtilMath.subtract(pos, this.lastMousePos);
            this.lastMousePos = pos;
            let cPos = UtilMath.add(this.moveBackPos, UtilMath.subtract(pos, this.moveStartPos));
            this.x = cPos.x;
            this.y = cPos.y;
            //this.checkPosition();
            event.stopPropagation();
        };
        this.onCovePanelMouseup = (event) => {
            this.covePanel.visible = false;
            this.editorParent.off("mousemove", this.onCovePanelMousemove);
            this.covePanel.off("mouseup", this.onCovePanelMouseup);
            this.editorParent.operationEnd();
        };
        this.showPoint = point;
        this.addChild(this.bg);
        this.addChild(this.covePanel);
        this.interactive = true;
        this.on("mousedown", (event) => {
            event.stopPropagation();
        });
        this.covePanel.interactive = true;
        this.covePanel.cursor = "move";
        this.covePanel.on("mousedown", this.onCovePanelMousedown.bind(this));
    }
    setStyle(type, data) {
        switch (type) {
            case "color":
                this.textInput.setInputStyle("color", data);
                this.textInput.focus(true);
                this.autoSize();
                this.textInput.blur(true);
                this.autoSize2();
                this.fontColor = data;
                break;
            case "fontSize":
                // this.textInput.setInputStyle("fontSize", data);
                // this.autoSize2();
                this.fontSize = data;
                this.textInput.setInputStyle("fontSize", this.fontSize + 'px');
                this.textInput.focus(true);
                this.autoSize();
                this.textInput.blur(true);
                this.autoSize2();
                break;
        }
    }
    setSelect(bo) {
        this.isSelect = bo;
        if (!this.isSelect) {
            if (this.textInput.text) {
                this.covePanel.visible = true;
                this.autoSize2();
            }
            else {
                this.editorParent.removeItem(this);
            }
        }
        else {
            this.refreshShow();
        }
    }
    init(editor) {
        this.editorParent = editor;
        this.editorParent.addChild(this);
        this.createInput(editor.parent.pixi.renderer);
        this.refreshShow();
        this.position = this.showPoint;
        this.pivot = new PIXI.Point(this.topOffice, this.height / 2);
    }
    setParent(editor) {
        this.editorParent = editor;
        this.editorParent.addChild(this);
        this.position = this.showPoint;
        this.pivot = new PIXI.Point(this.topOffice, this.height / 2);
    }
    getItemData() {
        return { type: MarkMode.Text, data: { pos: { x: this.x, y: this.y }, text: this.textInput.text, fontSize: this.fontSize, color: this.fontColor } };
    }
    setItemData(obj) {
        let data = obj.data;
        this.fontSize = data.fontSize;
        this.fontColor = data.color;
        this.textInput.text = data.text;
        this.x = data.pos.x;
        this.y = data.pos.y;
        this.textInput.setInputStyle("color", this.fontColor);
        this.textInput.setInputStyle("fontSize", this.fontSize + 'px');
        this.textInput.focus(true);
        this.autoSize();
        this.textInput.blur(true);
        this.autoSize2();
    }
    setMousedown() {
        this.onRender = () => {
            this.editorParent.setSelectItem(this);
            this.textInput.focus(true);
            this.covePanel.visible = false;
            this.onRender = () => { };
        };
    }
    onCovePanelMousedown(event) {
        event.stopPropagation();
        this.editorParent.off("mousemove", this.onCovePanelMousemove);
        this.covePanel.off("mouseup", this.onCovePanelMouseup);
        this.editorParent.on("mousemove", this.onCovePanelMousemove);
        this.covePanel.on("mouseup", this.onCovePanelMouseup);
        this.editorParent.setSelectItem(this);
        this.lastMousePos = this.parent.toLocal(event.global);
        this.moveStartPos = this.lastMousePos;
        this.moveBackPos = new PIXI.Point(this.x, this.y);
        if (this.editorParent.getMode() != MarkMode.Text) {
            this.editorParent.antoSwitchMode(MarkMode.Text);
        }
        this.editorParent.operationStart();
    }
    checkPosition() {
        if (this.x < this.topOffice) {
            this.x = this.topOffice;
        }
        if (this.y < this.height / 2) {
            this.y = this.height / 2;
        }
        console.log(this.editorParent.width, this.editorParent.height);
        if (this.x > this.editorParent.width - this.width + this.topOffice) {
            this.x = this.editorParent.width - this.width + this.topOffice;
        }
        if (this.y > this.editorParent.height - this.height / 2) {
            this.y = this.editorParent.height - this.height / 2;
        }
    }
    createInput(renderer) {
        let textStyle = {
            input: {
                fontSize: this.fontSize + 'px',
                padding: `${this.top}px ${this.right + this.overflowWidth}px ${this.bottom}px ${this.left}px`,
                width: '1px',
                height: (this.minInputHeight) + 'px',
                color: '#ff0000',
                textAlign: "left",
                verticalAlign: "middle",
                multiline: true
            },
            box: {
                default: { rounded: 1, stroke: { color: 0xCBCEE0, width: 3 } },
                focused: { rounded: 1, stroke: { color: 0xABAFC6, width: 3 } },
                disabled: { rounded: 1, stroke: { color: 0xCBCEE0, width: 3 } }
            }
        };
        let text = new TextInput(textStyle, renderer);
        text.input.style.whiteSpace = "nowrap";
        text.on("input", (e) => {
            this.autoSize();
        });
        this.addChildAt(text, 1);
        this.textInput = text;
        text.on("focus", () => {
            //console.log("focus");
            this.editorParent.operationStart();
        });
        text.on("blur", () => {
            //console.log("blur");
            this.editorParent.operationEnd();
        });
        return text;
    }
    autoSize() {
        this.textInput.input.style.height = "1px";
        let scrollHeight = this.textInput.input.scrollHeight;
        this.textHeight = scrollHeight - this.top - this.bottom;
        this.textInput.input.style.height = this.textHeight + "px";
        this.textInput.input.style.width = "1px";
        this.textInput.input.style.padding = `${this.top}px 0px ${this.bottom}px 0px`;
        let scrollWidth = this.textInput.input.scrollWidth;
        this.textInput.input.style.width = scrollWidth + "px";
        this.textWidth = scrollWidth;
        //this.textInput.input.style.padding = `${this.top}px ${this.right + this.overflowWidth}px ${this.bottom}px ${this.left}px`;
        scrollWidth += this.left + this.right + this.overflowWidth;
        this.boundsArea = new PIXI.Rectangle(0, 0, scrollWidth, scrollHeight);
        this.hitArea = new PIXI.Rectangle(0, 0, scrollWidth, scrollHeight);
        this.covePanel.hitArea = new PIXI.Rectangle(0, 0, scrollWidth, scrollHeight);
        this.textInput.setInputStyle("padding", `${this.top}px ${this.right + this.overflowWidth}px ${this.bottom}px ${this.left}px`);
        this.refreshShow();
    }
    autoSize2() {
        let scrollHeight = this.textHeight + this.top + this.bottom;
        let scrollWidth = this.textWidth + this.left + this.right;
        this.boundsArea = new PIXI.Rectangle(0, 0, scrollWidth, scrollHeight);
        this.hitArea = new PIXI.Rectangle(0, 0, scrollWidth, scrollHeight);
        this.covePanel.hitArea = new PIXI.Rectangle(0, 0, scrollWidth, scrollHeight);
        this.textInput.setInputStyle("padding", `${this.top}px ${this.right}px ${this.bottom}px ${this.left}px`);
        this.refreshShow();
    }
    refreshShow() {
        this.bg.clear();
        if (this.isSelect) {
            this.bg.rect(0, 0, this.width, this.height);
            this.bg.stroke({ width: this.lineWidth, color: 0xff0000 });
        }
    }
    refresh() {
    }
    /** 在全局中的比例 */
    get globalScale() {
        let x = 1;
        let y = 1;
        let scale;
        let parent = this.parent;
        while (parent) {
            scale = parent.scale;
            x *= scale.x;
            y *= scale.y;
            parent = parent.parent;
        }
        return new PIXI.Point(x, y);
    }
}
