/**染色体计数数据 */
export default class CountData {
    constructor() {
        /**记录点 */
        this.points = [];
    }
    clone() {
        let copy = new CountData();
        copy.points = this.points.map(p => ({ x: p.x, y: p.y }));
        return copy;
    }
    static createFromString(value) {
        let data = new CountData();
        data.points = JSON.parse(value).points;
        return data;
    }
}
