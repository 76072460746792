import * as PIXI from "pixi.js";
export default class CountItem extends PIXI.Graphics {
    constructor(id, group, canvas) {
        super();
        this.isMouseDown = false;
        this._id = id;
        this.group = group;
        this.canvas = canvas;
        this.on("mousedown", this.onMouseDown, this);
        // this.group.on("mousemove", this.onMouseMove, this);
        // this.group.on("mouseup", this.onMouseUp, this);
        //this.on("click", this.onClick, this);
    }
    get id() { return this._id; }
    onMouseDown(event) {
        event.stopImmediatePropagation();
        this.group.removeItem(this);
        this.destroy();
        // this.hitArea = undefined;
        // this.isMouseDown = true;
        // this.group.itemMoveStart(this._id);
    }
    onMouseMove(event) {
        if (!this.isMouseDown) {
            return;
        }
        event.stopImmediatePropagation();
        let temp = { x: 0, y: 0 };
        temp.x = (event.screenX - this.canvas.stage.x) / this.canvas.stage.scale.x;
        temp.y = (event.screenY - this.canvas.stage.y) / this.canvas.stage.scale.y;
        this.x = temp.x;
        this.y = temp.y;
    }
    onMouseUp(event) {
        if (!this.isMouseDown) {
            return;
        }
        event.stopImmediatePropagation();
        this.isMouseDown = false;
        this.group.itemMoveEnd();
    }
    onClick(event) {
        this.group.removeItem(this);
        this.destroy();
    }
}
