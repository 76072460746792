import * as PIXI from "pixi.js";
import StyleEditor from "./StyleEditor";
export default class TextSelectBox extends StyleEditor {
    set fontSize(size) {
        this._size = size;
    }
    get fontSize() {
        return this._size;
    }
    constructor() {
        super();
        this.bg = new PIXI.Graphics();
        this.bgColor = 0xF2F6FD;
        //protected bgColor: number = 0xFF0000;
        this.selectBox = new PIXI.Container();
        this.selectBoxGraphics = new PIXI.Graphics();
        this.selectBoxText = new PIXI.Text();
        this.sizeScroll = new PIXI.Container();
        this.sizeScrollBg = new PIXI.Graphics();
        this.sizeViewport = new PIXI.Container();
        this.fontSizeMask = new PIXI.Graphics();
        this.sizeContent = new PIXI.Container();
        this.sizeContentBg = new PIXI.Graphics();
        this.state = SelectBoxState.close;
        this._size = 10;
        this.sizeListWidth = 48;
        this.sizeListHeight = 118;
        this.itemWidth = 48;
        this.itemHeight = 20;
        this.fontSizeList = [
            8, 9, 10, 11, 12, 14, 16, 18, 20, 22, 24
        ];
        this.init();
    }
    init() {
        this.addChild(this.bg);
        this.addChild(this.selectBox);
        this.selectBox.addChild(this.selectBoxGraphics);
        this.selectBox.addChild(this.selectBoxText);
        this.addChild(this.sizeScroll);
        this.sizeScroll.addChild(this.sizeScrollBg);
        let x = 100;
        this.boundsArea = new PIXI.Rectangle(0, 0, x, 40);
        this.bg.interactive = true;
        this.bg.roundRect(0, 0, x, 40, 1);
        this.bg.fill({ color: this.bgColor });
        this.bg.hitArea = new PIXI.Rectangle(0, 0, x, 40);
        this.selectBox.interactive = true;
        this.selectBox.on("mouseenter", () => {
            console.log("mouseenter");
            if (this.state == SelectBoxState.close) {
                this.drawSelectBox(SelectBoxState.suspension);
            }
        });
        this.selectBox.on("mouseout", () => {
            console.log("mouseout");
            if (SelectBoxState.suspension == this.state || SelectBoxState.select == this.state) {
                this.drawSelectBox(SelectBoxState.close);
            }
        });
        this.selectBox.on("click", () => {
            console.log("click");
            if (SelectBoxState.open == this.state) {
                this.drawSelectBox(SelectBoxState.select);
                this.sizeScroll.visible = false;
            }
            else {
                this.drawSelectBox(SelectBoxState.open);
                this.sizeScroll.visible = true;
            }
        });
        this.drawSelectBox(SelectBoxState.close);
        this.initScorll();
    }
    initScorll() {
        let width = 50;
        let height = 120;
        let top = 1;
        let bottom = 1;
        let right = 1;
        let left = 1;
        this.sizeScroll.addChild(this.sizeScrollBg);
        this.sizeScroll.addChild(this.sizeViewport);
        this.sizeScroll.hitArea = new PIXI.Rectangle(0, 0, width, height);
        this.sizeScroll.boundsArea = new PIXI.Rectangle(0, 0, width, height);
        this.sizeScrollBg.roundRect(0, 0, width, height, 1);
        this.sizeScrollBg.fill(0xffffff);
        this.sizeScrollBg.stroke({ color: 0x1583DD, alpha: 1 });
        this.sizeViewport.addChild(this.sizeContent);
        this.sizeViewport.addChild(this.fontSizeMask);
        this.sizeViewport.boundsArea = new PIXI.Rectangle(0, 0, width, height);
        this.fontSizeMask.rect(top, left, width - left - right, height - top - bottom);
        this.sizeContent.addChild(this.sizeContentBg);
        this.fontSizeMask.fill();
        this.sizeViewport.mask = this.fontSizeMask;
        this.sizeScroll.x = 10;
        this.sizeScroll.y = 15 - height;
        let itemWidth = this.itemWidth;
        let itemHeight = this.itemHeight;
        this.drawListSelect(-1);
        this.sizeScrollItemList = new Array(this.fontSizeList.length);
        for (let i = 0; i < this.sizeScrollItemList.length; i++) {
            this.sizeScrollItemList[i] = new PIXI.Text();
            this.sizeContent.addChild(this.sizeScrollItemList[i]);
            this.sizeScrollItemList[i].anchor = new PIXI.Point(0.5, 0);
            this.sizeScrollItemList[i].text = this.fontSizeList[i];
            this.sizeScrollItemList[i].style.fontSize = 16;
            this.sizeScrollItemList[i].style.align = "center";
            this.sizeScrollItemList[i].x = 25;
            this.sizeScrollItemList[i].y = top + i * itemHeight + 1;
        }
        this.sizeContent.boundsArea = new PIXI.Rectangle(0, 0, this.width, this.sizeScrollItemList.length * itemHeight + top + bottom);
        this.sizeScroll.interactive = true;
        this.sizeScroll.on("mousemove", (event) => {
            let pos = this.sizeContent.toLocal(event.global);
            let index = Math.floor((pos.y - top) / this.itemHeight);
            this.drawListSelect(index);
        });
        this.sizeScroll.on("wheel", (event) => {
            this.moveFontSizeList(event.deltaY);
        });
        this.sizeScroll.on("click", (event) => {
            let pos = this.sizeContent.toLocal(event.global);
            let index = Math.floor((pos.y - top) / this.itemHeight);
            console.log(index, this.fontSizeList.length);
            if (index >= 0 && index < this.fontSizeList.length) {
                this.fontSize = this.fontSizeList[index];
            }
            console.log(this.fontSize);
            console.log(this.selectBoxText);
            this.selectBoxText.text = "16"; //this.fontSize;
            this.drawSelectBox(SelectBoxState.select);
            this.sizeScroll.visible = false;
            if (this.styleChangedFunc) {
                this.styleChangedFunc("fontSize", this.fontSize);
            }
        });
        this.sizeScroll.visible = false;
    }
    drawSelectBox(state) {
        // let x = 10;
        // let y = 10;
        this.state = state;
        this.selectBox.x = 10;
        this.selectBox.y = 10;
        let width = 50;
        let height = 20;
        let lineWidth = 1;
        let color = 0xBDBDBD;
        let points = this.getTriangle(false);
        switch (state) {
            case SelectBoxState.open:
                lineWidth = 1;
                color = 0x1583DD;
                points = this.getTriangle(true);
                break;
            case SelectBoxState.select:
                lineWidth = 1;
                color = 0x1583DD;
                break;
            case SelectBoxState.suspension:
                lineWidth = 2;
                color = 0x1583DD;
                break;
            default:
                break;
        }
        this.selectBoxGraphics.roundRect(0, 0, width, height, 1);
        this.selectBoxGraphics.fill({ color: 0xffffff });
        this.selectBoxGraphics.stroke({ color, width: lineWidth });
        this.selectBoxGraphics.beginPath();
        this.selectBoxGraphics.moveTo(points[0].x, points[0].y);
        this.selectBoxGraphics.lineTo(points[1].x, points[1].y);
        this.selectBoxGraphics.lineTo(points[2].x, points[2].y);
        this.selectBoxGraphics.closePath();
        this.selectBoxGraphics.fill({ color: 0x434343 });
        this.selectBoxText.x = 5;
        this.selectBoxText.y = 20 / 2;
        this.selectBoxText.text = this.fontSize;
        this.selectBoxText.style.fontSize = 16;
        this.selectBoxText.anchor = new PIXI.Point(0, 0.5);
    }
    drawListSelect(index) {
        this.sizeContentBg.clear();
        if (index >= 0) {
            let itemWidth = this.itemWidth;
            let itemHeight = this.itemHeight;
            let top = 1;
            let bottom = 1;
            let left = 1;
            let right = 1;
            this.sizeContentBg.rect(left, top + index * itemHeight, itemWidth, itemHeight);
            this.sizeContentBg.fill({ color: 0x1583DD });
        }
    }
    getTriangle(bo) {
        let x = 42;
        let y = 7.5;
        let pointList = [];
        if (bo) {
            pointList = [new PIXI.Point(x + 0, y + 0), new PIXI.Point(x - 5, y + 5), new PIXI.Point(x + 5, y + 5)];
        }
        else {
            pointList = [new PIXI.Point(x + 0, y + 5), new PIXI.Point(x - 5, y + 0), new PIXI.Point(x + 5, y + 0)];
        }
        return pointList;
    }
    moveFontSizeList(delta) {
        console.log(delta);
        let y = -this.sizeContent.y;
        console.log(y);
        y += delta;
        let maxY = this.sizeContent.height - this.sizeScroll.height;
        console.log(this.sizeContent.height, this.sizeScroll.height);
        y = Math.max(0, Math.min(maxY, y));
        console.log(y);
        this.sizeContent.y = -y;
    }
    getSytleData() {
        return { fontSize: this.fontSize };
    }
    getSaveStyleData() {
        return { type: "TextSelect", data: this.getSytleData() };
    }
    setSaveStyleData(data) {
        if (data.type != "TextSelect") {
            return;
        }
        let fontSize = data.data.fontSize;
        let index = 0;
        for (let i = 0; i < this.fontSizeList.length; i++) {
            if (this.fontSizeList[i] == fontSize) {
                index = i;
            }
        }
        this.fontSize = this.fontSizeList[index];
        this.selectBoxText.text = "16"; //this.fontSize;
        this.drawSelectBox(SelectBoxState.select);
        this.sizeScroll.visible = false;
        //if(this.styleChangedFunc){ this.styleChangedFunc("fontSize", this.fontSize); }
    }
}
var SelectBoxState;
(function (SelectBoxState) {
    SelectBoxState[SelectBoxState["close"] = 0] = "close";
    SelectBoxState[SelectBoxState["select"] = 1] = "select";
    SelectBoxState[SelectBoxState["open"] = 2] = "open";
    SelectBoxState[SelectBoxState["suspension"] = 3] = "suspension";
})(SelectBoxState || (SelectBoxState = {}));
