export default class ImageInfo {
    constructor(id, url) {
        this.isShow = true;
        this.autoProcessingMinValue = -1;
        this.autoProcessingMaxValue = -1;
        this.manualProcessingSavedValue = "";
        this.manualProcessingShowValue = "";
        this.id = id;
        this.url = url;
    }
}
