import * as PIXI from "pixi.js";
import StyleEditor from "./StyleEditor";
export default class ColorSelectBox extends StyleEditor {
    get selectColor() {
        return this.color;
    }
    constructor() {
        super();
        this.bg = new PIXI.Graphics();
        this.bgColor = 0xF2F6FD;
        this.selectBlock = new PIXI.Graphics();
        this.color = 0xFB3838;
        this.blockBg = 0xFFFFFF;
        this.colorList = [
            0x000000, 0xFFFFFF,
            0x808080, 0xC0C0C0,
            0x800000, 0xFB3838,
            0xF7883A, 0xFFFF00,
            0x308430, 0x99CC00,
            0x385AD3, 0x3894E4,
            0x800080, 0xF31BF3,
            0x009999, 0x16DCDC,
        ];
        this.colorBlockList = [];
        this.colorBlockBigWidth = 40;
        this.colorBlockBigHeight = 40;
        this.colorBlockWidth = 100;
        this.colorBlockHeight = 100;
        this.init();
    }
    init() {
        this.addChild(this.bg);
        this.addChild(this.selectBlock);
        this.colorBlockList = new Array(this.colorList.length);
        this.drawSelectBlock(0xFB3838);
        let x = 40;
        let y = 5;
        for (let i = 0; i < this.colorBlockList.length; i++) {
            let graphic = new PIXI.Graphics();
            let color = this.colorList[i];
            let colorBlock = new ColorBlockInfo();
            colorBlock.color = color;
            colorBlock.x = x;
            colorBlock.y = y;
            if (i % 2 == 0) {
                y += 17;
            }
            else {
                y = 5;
                x += 17;
            }
            colorBlock.height = 13;
            colorBlock.width = 13;
            this.drawColorBlock(graphic, colorBlock, false);
            this.colorBlockList[i] = graphic;
            this.addChild(this.colorBlockList[i]);
            this.colorBlockList[i].interactive = true;
            this.colorBlockList[i].on("mouseenter", () => { this.onMouseEnter(graphic, colorBlock); });
            this.colorBlockList[i].on("mouseout", () => { this.onMouseOut(graphic, colorBlock); });
            this.colorBlockList[i].on("click", () => { this.onClick(color); });
        }
        this.boundsArea = new PIXI.Rectangle(0, 0, x, 40);
        this.bg.rect(0, 0, x, 40);
        this.bg.fill({ color: this.bgColor });
        this.bg.hitArea = new PIXI.Rectangle(0, 0, x, 40);
        this.bg.interactive = true;
    }
    onMouseEnter(graphic, color) {
        this.drawColorBlock(graphic, color, true);
    }
    onMouseOut(graphic, color) {
        this.drawColorBlock(graphic, color, false);
    }
    onClick(color) {
        this.drawSelectBlock(color);
        if (this.styleChangedFunc) {
            this.styleChangedFunc("color", this.color);
        }
    }
    drawSelectBlock(color) {
        let colorBlock = new ColorBlockInfo();
        colorBlock.color = color;
        colorBlock.x = 6;
        colorBlock.y = 6;
        colorBlock.height = 28;
        colorBlock.width = 28;
        this.color = color;
        this.drawColorBlock(this.selectBlock, colorBlock, true);
    }
    drawColorBlock(graphic, colorBlock, isSelect) {
        graphic.clear();
        let info = colorBlock;
        let stroke = 0x808080;
        let lineWidth = 1;
        if (isSelect) {
            graphic.rect(info.x - 1, info.y - 1, info.width + 2, info.height + 2);
            graphic.stroke({ color: stroke, width: lineWidth });
            graphic.fill({ color: 0xffffff });
            graphic.rect(info.x, info.y, info.width, info.height);
            graphic.stroke({ color: 0xffffff, width: lineWidth });
            graphic.fill({ color: info.color });
        }
        else {
            graphic.rect(info.x, info.y, info.width, info.height);
            graphic.stroke({ color: stroke, width: lineWidth });
            graphic.fill({ color: info.color });
        }
    }
    getSytleData() {
        return { color: this.color };
    }
    getSaveStyleData() {
        return { type: "ColorSelect", data: this.getSytleData() };
    }
    setSaveStyleData(data) {
        if (data.type != "ColorSelect") {
            return;
        }
        let color = data.data.color;
        let index = 0;
        for (let i = 0; i < this.colorList.length; i++) {
            if (this.colorList[i] == color) {
                index = i;
                break;
            }
        }
        color = this.colorList[index];
        this.drawSelectBlock(color);
    }
}
class ColorBlockInfo {
    constructor() {
        this.color = 0xffffff;
        this.x = 0;
        this.y = 0;
        this.width = 100;
        this.height = 100;
    }
}
