import * as PIXI from "pixi.js";
import ColorCurveUI from "./Core/Tools/Curve/ColorCurveUI";
export class CurveCanvas {
    get curve() {
        return this.curveUI.curve;
    }
    // private offset: PIXI.PointData = {x: 10, y: 10};
    // private curveWin: PIXI.PointData = {x: 300, y: 300};
    // private curveWidth: number = 2;
    // private curvePointSize: number = 6;
    // private gradientWidth:number = 10;
    constructor(initCom) {
        this.pixi = new PIXI.Application();
        this.mainCanvas = document.getElementById("curveCanvas");
        //this.mainCanvas = document.getElementById("mainCanvas") as HTMLDivElement;
        this.pixi.init({ powerPreference: "high-performance", antialias: false, resolution: 2, resizeTo: this.mainCanvas }).then(() => {
            this.mainCanvas.appendChild(this.pixi.canvas);
            // let styleStr = `scale(0.5) translate(${-this.pixi.canvas.width / 2}px, ${-this.pixi.canvas.height / 2}px)`;
            // this.pixi.canvas.style.transform = styleStr;
            this.pixi.canvas.style.width = "100%";
            this.pixi.canvas.style.height = "100%";
            this.pixi.stage.boundsArea = new PIXI.Rectangle(0, 0, this.pixi.screen.width, this.pixi.screen.height);
            this.bg = new PIXI.Graphics().rect(0, 0, this.pixi.screen.width, this.pixi.screen.height).fill({ color: 0xffffff });
            this.pixi.stage.addChild(this.bg);
            let curveUI = new ColorCurveUI();
            let len = Math.min(this.pixi.screen.width, this.pixi.screen.height);
            curveUI.scale = len / 400;
            this.curveUI = curveUI;
            this.pixi.stage.addChild(curveUI);
            let event = new Event("createCurveCanvas");
            event.curveCanvas = this;
            console.log("创建曲线，发送曲线事件");
            window.dispatchEvent(event);
            if (initCom) {
                initCom();
            }
        }).catch((e) => {
            console.error("PIXICanvas create error: ", e);
        });
    }
    drawCurveWin() {
        let x = 0;
        let y = 0;
        let width = 300;
        let height = 300;
        let count = 4;
        let widthStep = width / count;
        let heightStep = height / count;
        for (let i = 1; i < count; i++) {
            let y1 = y + heightStep * i;
            this.bg.moveTo(x, y1);
            this.bg.lineTo(width, y1);
            let x1 = x + widthStep * i;
            this.bg.moveTo(x1, y);
            this.bg.lineTo(x1, height);
        }
        this.bg.stroke({ color: 0xf1f1f1, width: 1 });
        this.bg.rect(x, y, width, height);
        this.bg.stroke({ color: 0x909090, width: 2 });
    }
    drawHorizontalGradient() {
        let h = 20;
        let w = 300;
        let x = 0;
        let y = 0;
        if (!this.horizontalGradient) {
            this.horizontalGradient = new PIXI.Graphics();
            this.pixi.stage.addChild(this.horizontalGradient);
        }
        if (!this.fillHorizontalGradient) {
            this.fillHorizontalGradient = new PIXI.FillGradient(0, 0, 0, 0);
            this.fillHorizontalGradient.addColorStop(0, 0x000000);
            this.fillHorizontalGradient.addColorStop(1, 0xffffff);
        }
        this.horizontalGradient.clear();
        this.fillHorizontalGradient.x1 = w;
        this.horizontalGradient.x = x;
        this.horizontalGradient.y = y;
        this.horizontalGradient.rect(0, 0, w, h).fill(this.fillHorizontalGradient);
    }
    drawVerticalGradient() {
        let h = 300;
        let w = 20;
        let x = 0;
        let y = 0;
        if (!this.verticalGradient) {
            this.verticalGradient = new PIXI.Graphics();
            this.pixi.stage.addChild(this.verticalGradient);
        }
        if (!this.fillVerticalGradient) {
            this.fillVerticalGradient = new PIXI.FillGradient(0, 0, 0, 0);
            this.fillVerticalGradient.addColorStop(1, 0x000000);
            this.fillVerticalGradient.addColorStop(0, 0xffffff);
        }
        this.verticalGradient.clear();
        this.fillVerticalGradient.y1 = h;
        this.verticalGradient.x = x;
        this.verticalGradient.y = y;
        this.verticalGradient.rect(0, 0, w, h).fill(this.fillVerticalGradient);
    }
    /** 设曲线模式（通道） */
    setCurveMode(mode) {
        this.curveUI.curve.setMode(mode);
    }
    /** 设置曲线数据 */
    setCurveData(json) {
        if (json) {
            this.curveUI.curve.setCurveData(json);
        }
        else {
            this.curveUI.curve.resetCurveData();
        }
    }
    resetCurveData(json) {
        //this.resetCurveImage();
        this.setCurveData(json);
    }
    resetCurveImage() {
        this.curveUI.emit("resetCurveImage");
    }
    /** 获取曲线数据 */
    getCurveData() {
        return this.curveUI.curve.getCurveData();
    }
    onCurveConfirm() {
        this.curveUI.emit("curveConfirm");
    }
    /** 曲线取消 */
    onCurveCancel() {
        this.curveUI.emit("curveCancel");
    }
    /** 锐化 */
    sharpness() {
        this.curveUI.emit("sharpness");
    }
    on(event, fn, context) {
        this.curveUI.curve.on(event, fn, context);
        this.curveUI.on(event, fn, context);
    }
    off(event, fn, context, once) {
        this.curveUI.curve.off(event, fn, context, once);
        this.curveUI.off(event, fn, context, once);
    }
    destroy() {
    }
    resize() {
        this.pixi.resize();
        //this.pixi.resizeTo = this.mainCanvas;
        let len = Math.min(this.pixi.screen.width, this.pixi.screen.height);
        this.curveUI.scale = len / 400;
    }
}
window.CurveCanvas = CurveCanvas;
