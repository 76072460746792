import { Container } from "pixi.js";
export default class StyleEditor extends Container {
    onStyleChanged(func) {
        this.styleChangedFunc = func;
    }
    getSytleData() {
        return {};
    }
    getSaveStyleData() {
        return {};
    }
    setSaveStyleData(data) {
    }
}
