import BoundsData from "./BoundsData";
export default class MainData {
    constructor() {
        this.language = 0;
        this.id = "";
        this.mainImgUrl = "";
        this.enhancedImgUrl = "";
        this.noBGImgUrl = "";
        this.karyotypeImgUrl = "";
        this.karyotypeNoXYImgUrl = "";
        /** 不遮挡轮廓图 */
        this.outlineImgUrl = "";
        /** 遮挡性染色体轮廓图 */
        this.outlineImgUrl1 = "";
        /** 遮挡性染色体且不含轮廓的轮廓图 */
        this.outlineImgUrl2 = "";
        this.chromosomePairsIng = [];
        this.singleData = [];
        this.markData = [];
        this.countData = "";
        this.curveData = "";
    }
    clone() {
        let item = new MainData();
        item.language = this.language;
        item.id = this.id;
        item.mainImgUrl = this.mainImgUrl;
        item.enhancedImgUrl = this.enhancedImgUrl;
        item.noBGImgUrl = this.noBGImgUrl;
        item.karyotypeImgUrl = this.karyotypeImgUrl;
        item.karyotypeNoXYImgUrl = this.karyotypeNoXYImgUrl;
        item.outlineImgUrl = this.outlineImgUrl;
        item.outlineImgUrl1 = this.outlineImgUrl1;
        item.outlineImgUrl2 = this.outlineImgUrl2;
        for (let i = 0; i < this.chromosomePairsIng.length; i++) {
            item.chromosomePairsIng[i] = this.chromosomePairsIng[i];
        }
        for (let i = 0; i < this.singleData.length; i++) {
            item.singleData[i] = this.singleData[i].clone();
        }
        for (let i = 0; i < this.markData.length; i++) {
            item.markData[i] = this.markData[i];
        }
        item.countData = this.countData;
        item.curveData = this.curveData;
        return item;
    }
    static getDataFromJson(json) {
        let data = new MainData();
        data.language = json.language;
        data.id = json.id;
        data.mainImgUrl = json.mainImgUrl;
        data.enhancedImgUrl = json.enhancedImgUrl;
        data.noBGImgUrl = json.noBGImgUrl;
        data.countData = json.countData;
        data.curveData = json.curveData;
        data.markData = json.markData;
        data.karyotypeImgUrl = json.karyotypeImgUrl;
        data.karyotypeNoXYImgUrl = json.karyotypeNoXYImgUrl;
        data.outlineImgUrl = json.outlineImgUrl;
        data.chromosomePairsIng = json.chromosomePairsIng;
        if (json.singleData) {
            for (let i = 0; i < json.singleData.length; i++) {
                let boundsData = new BoundsData();
                boundsData.copyFrom(json.singleData[i]);
                data.singleData.push(boundsData);
            }
        }
        return data;
    }
}
