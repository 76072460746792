import * as PIXI from 'pixi.js';
import { IDIOGRAM_DATA } from '../Data/IdiogramData';
import TipsLab from './TipsLab';
import Container from '../Core/Component/Container';
export default class Idiogram extends Container {
    constructor(id, mode, canvas) {
        super();
        this.showHeight = 10;
        this.id = id;
        this.mode = mode;
        this.img = new PIXI.Sprite();
        this.addChild(this.img);
        this.loadTexture();
        this.interactive = true;
        this.on("mouseenter", () => {
            this.lab = new TipsLab("", 20);
            canvas.stage.addChild(this.lab);
        }, this);
        this.on("mousemove", (e) => {
            if (!this.data) {
                return;
            }
            let pos = this.toLocal(e.global);
            this.lab.position = canvas.stage.toLocal(e.global).clone();
            this.lab.scale = 1 / this.worldTransform.a;
            this.lab.x += 5;
            this.lab.y -= this.lab.height / 2;
            let posY = pos.y / this.height;
            for (let i = 0; i < this.data.length; i++) {
                if (posY >= this.data[i].minY && posY <= this.data[i].maxY) {
                    this.lab.setText(this.data[i].name);
                    break;
                }
            }
        }, this);
        this.on("mouseleave", () => {
            this.lab.visible = false;
            this.lab.destroy(true);
        }, this);
    }
    setHeight(height) {
        this.showHeight = height;
        this.img.height = this.showHeight;
        this.img.scale.x = this.img.scale.y;
        this.boundsArea = new PIXI.Rectangle(0, 0, this.img.width, this.img.height);
    }
    setId(id) {
        this.id = id;
        this.loadTexture();
    }
    setMode(mode) {
        this.mode = mode;
        this.loadTexture();
    }
    loadTexture() {
        if (this.id >= 25) {
            return;
        }
        this.data = IDIOGRAM_DATA[this.mode][this.id - 1];
        let fileName = this.id < 23 ? this.id.toString() : this.id === 23 ? 'x' : 'y';
        if (this.mode == 0) {
            fileName += "_300";
        }
        else if (this.mode == 1) {
            fileName += "_400";
        }
        else if (this.mode == 2) {
            fileName += "_550";
        }
        else if (this.mode == 3) {
            fileName += "_700";
        }
        else if (this.mode == 4) {
            fileName += "_850";
        }
        fileName += ".png";
        let image = new Image();
        image.onload = () => {
            var _a;
            let texture = PIXI.Texture.from(image);
            if (this.destroyed) {
                texture.destroy(true);
                return;
            }
            (_a = this.img.texture) === null || _a === void 0 ? void 0 : _a.destroy(true);
            this.img.texture = texture;
            this.img.height = this.showHeight;
            this.img.scale.x = this.img.scale.y;
            this.boundsArea = new PIXI.Rectangle(0, 0, this.img.width, this.img.height);
        };
        image.src = "./assets/idiogram/" + fileName;
        return;
        PIXI.Assets.load("./assets/idiogram/" + fileName).then((texture) => {
            this.img.texture = texture;
            this.img.height = this.showHeight;
            this.img.scale.x = this.img.scale.y;
            this.boundsArea = new PIXI.Rectangle(0, 0, this.img.width, this.img.height);
        });
    }
    onDestroy() {
        var _a;
        (_a = this.img.texture) === null || _a === void 0 ? void 0 : _a.destroy(true);
        this.img.destroy();
        this.id = undefined;
        this.mode = undefined;
        this.img = undefined;
        this.lab = undefined;
        this.showHeight = undefined;
        this.data = undefined;
        super.onDestroy();
    }
}
