import { Point } from "pixi.js";
import MarkText from "./MarkText";
import ColorSelectBox from "../ColorSelectBox";
import TextSelectBox from "../TextSelectBox";
export default class MaskTextEditor {
    constructor() {
        this.drawScale = 1;
        this.width = 0;
        this.height = 0;
    }
    setParent(parent) {
        this.parent = parent;
    }
    createItem(obj) {
        let data = obj.data;
        let markText = new MarkText(new Point(data.pos.x, data.pos.y));
        markText.editor = this;
        this.parent.addItem(markText);
        markText.setItemData(obj);
    }
    getStyleItem() {
        if (!this.styleItem) {
            this.styleItem = [new TextSelectBox(), new ColorSelectBox()];
        }
        if (this.styleData) {
            for (let i = 0; i < this.styleItem.length; i++) {
                for (let j = 0; j < this.styleData.length; j++) {
                    this.styleItem[i].setSaveStyleData(this.styleData[j]);
                }
            }
        }
        return this.styleItem;
    }
    onMousedown(event) {
        if (this.parent.selectIndex >= 0) {
            this.parent.setSelectItem(null);
            return;
        }
        event.stopPropagation();
        let parent = this.parent;
        parent.operationStart();
        let point = parent.toLocal(event.global);
        let markText = new MarkText(point);
        markText.editor = this;
        this.parent.addItem(markText);
        markText.setMousedown();
    }
    onMousemove(event) {
    }
    onMouseup(event) {
    }
    getSettingData() {
        let data = { type: "text", style: [] };
        if (this.styleItem) {
            for (let i = 0; i < this.styleItem.length; i++) {
                let item = this.styleItem[i];
                data.style.push(item.getSaveStyleData());
            }
        }
        return data;
    }
    setSettingData(data) {
        if (data.type != "text") {
            return;
        }
        this.styleData = data.style;
    }
}
