import * as PIXI from "pixi.js";
/*let textInput = new TextInput({
    input: {
        fontSize: '24px',
        padding: '12px',
        width: '500px',
        color: '#26272E',
        textAlign: "left",
        verticalAlign: "middle"
    },
    box: {
        default: {fill: 0xE8E9F3, rounded: 12, stroke: {color: 0xCBCEE0, width: 3}},
        focused: {fill: 0xE1E3EE, rounded: 12, stroke: {color: 0xABAFC6, width: 3}},
        disabled: {fill: 0xDBDBDB, rounded: 12}
    }
}, this.pixi.renderer!);
textInput.x = 500;
textInput.y = 300;
textInput.pivot.x = textInput.width / 2;
textInput.pivot.y = textInput.height / 2;
this.pixi.stage.addChild(textInput);*/
export default class TextInput extends PIXI.Container {
    constructor(styles, renderer) {
        super();
        this.isSystemOpreration = false;
        // window.parent.postMessage({ type:"", data:{} });
        // window.addEventListener("message", )
        this.inputStyle = Object.assign({
            position: 'absolute',
            background: 'none',
            border: 'none',
            outline: 'none',
            transformOrigin: '0 0',
            lineHeight: '1',
            fontFamily: "Arial",
            boxSizing: "content-box"
        }, styles.input);
        this.renderer = renderer;
        if (styles.box) {
            this.boxGenerator = this.defaultBoxGenerator(styles.box);
        }
        this.multiline = false;
        if (this.inputStyle.hasOwnProperty('multiline')) {
            this.multiline = this.inputStyle.multiline;
        }
        this.boxCache = new BoxCacheData();
        this.previous = new PreviousData();
        this.domAdded = false;
        this.domVisible = true;
        this._placeholder = '';
        this.placeholderColor = 0xa9a9a9;
        this.selection = [0, 0];
        this.restrictValue = '';
        this.createDOMInput();
        this.substituteText = true;
        this.setState('DEFAULT');
        this.addListeners();
        this.onRender = (() => {
            this.renderInternal();
        }).bind(this);
    }
    ;
    get input() {
        return this.domInput;
    }
    get substituteText() {
        return this.substituted;
    }
    set substituteText(value) {
        if (this.substituted == value) {
            return;
        }
        this.substituted = value;
        if (value) {
            this.createSurrogate();
            this.domVisible = false;
        }
        else {
            this.destroySurrogate();
            this.domVisible = true;
        }
        this.placeholder = this._placeholder;
        this.update();
    }
    get placeholder() {
        return this._placeholder;
    }
    set placeholder(text) {
        this._placeholder = text;
        if (this.substituted) {
            this.updateSurrogate();
            this.domInput.placeholder = '';
        }
        else {
            this.domInput.placeholder = text;
        }
    }
    get disabled() {
        return this._disabled;
    }
    set disabled(value) {
        this._disabled = value;
        this.domInput.disabled = value;
        this.setState(value ? 'DISABLED' : 'DEFAULT');
    }
    get maxLength() {
        return this._maxLength;
    }
    set maxLength(value) {
        this._maxLength = value;
        this.domInput.setAttribute('maxlength', value.toString());
    }
    get restrict() {
        return this.restrictRegex;
    }
    set restrict(regex) {
        if (regex instanceof RegExp) {
            let regexStr = regex.toString().slice(1, -1);
            if (regexStr.charAt(0) !== '^') {
                regexStr = '^' + regexStr;
            }
            if (regexStr.charAt(regexStr.length - 1) !== '$') {
                regexStr = regexStr + '$';
            }
            regex = new RegExp(regexStr);
        }
        else {
            regex = new RegExp('^[' + regex + ']*$');
        }
        this.restrictRegex = regex;
    }
    get text() {
        return this.domInput.value;
    }
    set text(value) {
        this.domInput.value = value;
        if (this.substituted) {
            this.updateSurrogate();
        }
    }
    get htmlInput() {
        return this.domInput;
    }
    focus(isSystemOpreration) {
        this.isSystemOpreration = isSystemOpreration;
        if (this.substituted && !this.domVisible) {
            this.setDOMInputVisible(true);
        }
        this.domInput.focus();
    }
    blur(isSystemOpreration) {
        this.isSystemOpreration = isSystemOpreration;
        this.domInput.blur();
    }
    select(isSystemOpreration) {
        this.focus(isSystemOpreration);
        this.domInput.select();
    }
    setInputStyle(key, value) {
        //@ts-ignore
        this.inputStyle[key] = value;
        if (key == "color") {
            let color = Number(value);
            let b = color % 0x100;
            let g = Math.floor(color / 0x100) % 0x100;
            let r = Math.floor(color / 0x10000);
            this.domInput.style.color = "rgb(" + r + "," + g + "," + b + ")";
        }
        else {
            //@ts-ignore
            this.domInput.style[key] = value;
        }
        if (this.substituted && (key === 'fontFamily' || key === 'fontSize')) {
            this.updateFontMetrics();
        }
        if (this.renderer) {
            this.update();
        }
    }
    destroy(options) {
        this.destroyBoxCache();
        super.destroy(options);
    }
    addListeners() {
        this.on('added', this.onAdded.bind(this));
        this.on('removed', this.onRemoved.bind(this));
        this.domInput.addEventListener('keydown', this.onInputKeyDown.bind(this));
        this.domInput.addEventListener('input', this.onInputInput.bind(this));
        this.domInput.addEventListener('keyup', this.onInputKeyUp.bind(this));
        this.domInput.addEventListener('focus', this.onFocused.bind(this));
        this.domInput.addEventListener('blur', this.onBlurred.bind(this));
    }
    onAdded() {
        var _a;
        //document.body.appendChild(this.domInput);
        (_a = this.renderer.canvas.parentElement) === null || _a === void 0 ? void 0 : _a.appendChild(this.domInput);
        this.domInput.style.display = 'none';
        this.domAdded = true;
    }
    onRemoved() {
        var _a;
        //document.body.removeChild(this.domInput);
        (_a = this.renderer.canvas.parentElement) === null || _a === void 0 ? void 0 : _a.removeChild(this.domInput);
        this.domAdded = false;
    }
    onInputKeyDown(e) {
        this.selection = [
            this.domInput.selectionStart,
            this.domInput.selectionEnd
        ];
        this.emit('keydown', e.keyCode);
    }
    onInputInput() {
        if (this.restrictRegex) {
            this.applyRestriction();
        }
        if (this.substituted) {
            this.updateSubstitution();
        }
        this.emit('input', this.text);
    }
    onInputKeyUp(e) {
        this.emit('keyup', e.keyCode);
    }
    onFocused() {
        this.setState('FOCUSED');
        //console.log("+++++++++++++++FOCUSED", this.isSystemOpreration);
        if (!this.isSystemOpreration) {
            this.emit('focus');
        }
        this.isSystemOpreration = false;
    }
    onBlurred() {
        this.setState('DEFAULT');
        //console.log("+++++++++++++++DEFAULT", this.isSystemOpreration);
        if (!this.isSystemOpreration) {
            this.emit('blur');
        }
        this.isSystemOpreration = false;
    }
    onSurrogateFocus() {
        this.setDOMInputVisible(true);
        //sometimes the input is not being focused by the mouseclick
        setTimeout(this.ensureFocus.bind(this), 10);
    }
    ensureFocus() {
        if (!this.hasFocus()) {
            this.focus(false);
        }
    }
    hasFocus() {
        return document.activeElement === this.domInput;
        //return (this.renderer.canvas.parentElement as HTMLDivElement).activeElement === this.domInput;
    }
    createDOMInput() {
        if (this.multiline) {
            this.domInput = document.createElement('textarea');
            this.domInput.style.resize = 'none';
        }
        else {
            this.domInput = document.createElement('input');
            this.domInput.type = 'text';
        }
        for (let key in this.inputStyle) {
            //@ts-ignore
            this.domInput.style[key] = this.inputStyle[key];
        }
    }
    setState(state) {
        this.state = state;
        this.updateBox();
        if (this.substituted) {
            this.updateSubstitution();
        }
    }
    update() {
        this.updateDOMInput();
        if (this.substituted) {
            this.updateSurrogate();
        }
        this.updateBox();
    }
    updateDOMInput() {
        var _a;
        if (!this.canvasBounds) {
            return;
        }
        // this.domInput.style.top = (this.canvasBounds.top || 0) + 'px';
        // this.domInput.style.left = (this.canvasBounds.left || 0) + 'px';
        if (this.renderer.canvas.parentElement != this.domInput.parentElement) {
            (_a = this.renderer.canvas.parentElement) === null || _a === void 0 ? void 0 : _a.appendChild(this.domInput);
        }
        //this.renderer.canvas.parentElement?.removeChild(this.domInput);
        this.domInput.style.top = '0px';
        this.domInput.style.left = '0px';
        this.domInput.style.transform = this.pixiMatrixToCSS(this.getDOMRelativeWorldTransform());
        this.domInput.style.opacity = this.alpha.toString();
        this.setDOMInputVisible(this.visible && this.domVisible);
        this.previous.canvasBounds = this.canvasBounds;
        this.previous.worldTransform = this.worldTransform.clone();
        this.previous.alpha = this.alpha;
        this.previous.visible = this.visible;
    }
    updateSubstitution() {
        if (this.state === 'FOCUSED') {
            this.domVisible = true;
            this.surrogate.visible = this.text.length === 0;
        }
        else {
            this.domVisible = false;
            this.surrogate.visible = true;
        }
        this.updateDOMInput();
        this.updateSurrogate();
    }
    updateSurrogate() {
        let padding = this.deriveSurrogatePadding();
        let inputBounds = this.getDOMInputBounds();
        this.surrogate.style = this.deriveSurrogateStyle();
        this.surrogate.style.padding = Math.max.apply(Math, padding);
        this.surrogate.y = padding[0];
        this.surrogate.x = padding[3];
        this.surrogate.text = this.deriveSurrogateText();
        this.surrogate.groupColor = 0xffff00;
        switch (this.surrogate.style.align) {
            case 'left':
                this.surrogate.x = padding[3];
                break;
            case 'center':
                this.surrogate.x = inputBounds.width * 0.5 - this.surrogate.width * 0.5;
                break;
            case 'right':
                this.surrogate.x = inputBounds.width - padding[1] - this.surrogate.width;
                break;
        }
        this.updateSurrogateHitbox(inputBounds);
        this.updateSurrogateMask(inputBounds, padding);
    }
    updateSurrogateHitbox(bounds) {
        this.surrogateHitbox.clear();
        this.surrogateHitbox.rect(0, 0, bounds.width, bounds.height);
        this.surrogateHitbox.fill(0);
        this.surrogateHitbox.interactive = !this.disabled;
    }
    updateSurrogateMask(bounds, padding) {
        this.surrogateMask.clear();
        this.surrogateMask.rect(padding[3], 0, bounds.width - padding[3] - padding[1], bounds.height);
        this.surrogateMask.fill(0);
    }
    updateBox() {
        if (!this.boxGenerator) {
            return;
        }
        if (this.needsNewBoxCache()) {
            this.buildBoxCache();
        }
        //@ts-ignore
        if (this.state == this.previous.state && this.box == this.boxCache[this.state]) {
            return;
        }
        if (this.box) {
            this.removeChild(this.box);
        }
        //@ts-ignore
        this.box = this.boxCache[this.state];
        this.addChildAt(this.box, 0);
        this.previous.state = this.state;
    }
    updateFontMetrics() {
        let style = this.deriveSurrogateStyle();
        let font = this.toFontString(style);
        this.fontMetrics = PIXI.CanvasTextMetrics.measureFont(font);
    }
    toFontString(style) {
        const fontSizeString = (typeof style.fontSize === 'number') ? `${style.fontSize}px` : style.fontSize;
        const genericFontFamilies = [
            'serif',
            'sans-serif',
            'monospace',
            'cursive',
            'fantasy',
            'system-ui'
        ];
        let fontFamilies = style.fontFamily;
        if (!Array.isArray(style.fontFamily)) {
            fontFamilies = style.fontFamily.split(',');
        }
        for (let i = fontFamilies.length - 1; i >= 0; i--) {
            let fontFamily = fontFamilies[i].trim();
            if (!(/([\"\'])[^\'\"]+\1/).test(fontFamily) && genericFontFamilies.indexOf(fontFamily) < 0) {
                fontFamily = `"${fontFamily}"`;
            }
            fontFamilies[i] = fontFamily;
        }
        return `${style.fontStyle} ${style.fontVariant} ${style.fontWeight} ${fontSizeString} ${fontFamilies.join(',')}`;
    }
    needsUpdate() {
        return (!this.comparePixiMatrices(this.worldTransform, this.previous.worldTransform)
            || !this.compareClientRects(this.canvasBounds, this.previous.canvasBounds)
            || this.alpha != this.previous.alpha
            || this.visible != this.previous.visible);
    }
    needsNewBoxCache() {
        let inputBounds = this.getDOMInputBounds();
        return (!this.previous.inputBounds
            || inputBounds.width != this.previous.inputBounds.width
            || inputBounds.height != this.previous.inputBounds.height);
    }
    buildBoxCache() {
        this.destroyBoxCache();
        let states = ['DEFAULT', 'FOCUSED', 'DISABLED'];
        let inputBounds = this.getDOMInputBounds();
        for (let state of states) {
            //@ts-ignore
            this.boxCache[state] = this.boxGenerator(inputBounds.width, inputBounds.height, state);
        }
        this.previous.inputBounds = inputBounds;
    }
    createSurrogate() {
        this.surrogateHitbox = new PIXI.Graphics();
        this.surrogateHitbox.alpha = 0;
        this.surrogateHitbox.interactive = true;
        this.surrogateHitbox.cursor = 'text';
        this.surrogateHitbox.on('pointerdown', this.onSurrogateFocus.bind(this));
        this.addChild(this.surrogateHitbox);
        this.surrogateMask = new PIXI.Graphics();
        this.addChild(this.surrogateMask);
        this.surrogate = new PIXI.Text({ text: '' });
        this.surrogate.hitArea = new PIXI.Rectangle(0, 0, 0, 0);
        this.addChild(this.surrogate);
        this.surrogate.mask = this.surrogateMask;
        //this.surrogate.resolution = 3;
        this.updateFontMetrics();
        this.updateSurrogate();
    }
    deriveSurrogateStyle() {
        let style = new PIXI.TextStyle();
        for (var key in this.inputStyle) {
            switch (key) {
                case 'color':
                    style.fill = this.inputStyle.color;
                    break;
                case 'fontFamily':
                case 'fontSize':
                case 'fontWeight':
                case 'fontVariant':
                case 'fontStyle':
                    //@ts-ignore
                    style[key] = this.inputStyle[key];
                    break;
                case 'letterSpacing':
                    style.letterSpacing = parseFloat(this.inputStyle.letterSpacing);
                    break;
                case 'textAlign':
                    //@ts-ignore
                    style.align = this.inputStyle.textAlign;
                    break;
            }
        }
        if (this.multiline) {
            style.lineHeight = style.fontSize;
            style.wordWrap = true;
            style.wordWrapWidth = this.getDOMInputBounds().width;
        }
        if (this.domInput.value.length === 0) {
            style.fill = this.placeholderColor;
        }
        return style;
    }
    deriveSurrogatePadding() {
        let indent = this.inputStyle.textIndent ? parseFloat(this.inputStyle.textIndent) : 0;
        if (this.inputStyle.padding && this.inputStyle.padding.length > 0) {
            let components = this.inputStyle.padding.trim().split(' ');
            if (components.length == 1) {
                let padding = parseFloat(components[0]);
                return [padding, padding, padding, padding + indent];
            }
            else if (components.length == 2) {
                let paddingV = parseFloat(components[0]);
                let paddingH = parseFloat(components[1]);
                return [paddingV, paddingH, paddingV, paddingH + indent];
            }
            else if (components.length == 4) {
                let padding = components.map(component => {
                    return parseFloat(component);
                });
                padding[3] += indent;
                return padding;
            }
        }
        return [0, 0, 0, indent];
    }
    deriveSurrogateText() {
        if (this.domInput.value.length === 0) {
            return this._placeholder;
        }
        if (this.domInput.type == 'password') {
            return '•'.repeat(this.domInput.value.length);
        }
        return this.domInput.value;
    }
    renderInternal() {
        this.resolution = 1 /*this.renderer.resolution*/;
        this.canvasBounds = this.getCanvasBounds();
        if (this.needsUpdate()) {
            this.update();
        }
    }
    getDOMRelativeWorldTransform() {
        let canvasBounds = this.renderer.canvas.getBoundingClientRect();
        let matrix = this.worldTransform.clone();
        matrix.scale(this.resolution, this.resolution);
        matrix.scale(canvasBounds.width / this.renderer.width, canvasBounds.height / this.renderer.height);
        return matrix;
    }
    getCanvasBounds() {
        let rect = this.renderer.canvas.getBoundingClientRect();
        let bounds = { top: rect.top, left: rect.left, width: rect.width, height: rect.height };
        bounds.left += window.scrollX;
        bounds.top += window.scrollY;
        return bounds;
    }
    getDOMInputBounds() {
        var _a, _b;
        let removeAfter = false;
        if (!this.domAdded) {
            //document.body.appendChild(this.domInput);
            (_a = this.renderer.canvas.parentElement) === null || _a === void 0 ? void 0 : _a.appendChild(this.domInput);
            removeAfter = true;
        }
        let orgTransform = this.domInput.style.transform;
        let orgDisplay = this.domInput.style.display;
        this.domInput.style.transform = '';
        this.domInput.style.display = 'block';
        let bounds = this.domInput.getBoundingClientRect();
        this.domInput.style.transform = orgTransform;
        this.domInput.style.display = orgDisplay;
        //if(removeAfter) { document.body.removeChild(this.domInput); }
        if (removeAfter) {
            (_b = this.renderer.canvas.parentElement) === null || _b === void 0 ? void 0 : _b.removeChild(this.domInput);
        }
        return bounds;
    }
    setDOMInputVisible(value) {
        this.domInput.style.display = value ? 'block' : 'none';
    }
    pixiMatrixToCSS(m) {
        return 'matrix(' + [m.a, m.b, m.c, m.d, m.tx, m.ty].join(',') + ')';
    }
    comparePixiMatrices(m1, m2) {
        if (!m1 || !m2) {
            return false;
        }
        return (m1.a == m2.a
            && m1.b == m2.b
            && m1.c == m2.c
            && m1.d == m2.d
            && m1.tx == m2.tx
            && m1.ty == m2.ty);
    }
    compareClientRects(r1, r2) {
        if (!r1 || !r2) {
            return false;
        }
        return (r1.left == r2.left
            && r1.top == r2.top
            && r1.width == r2.width
            && r1.height == r2.height);
    }
    destroyBoxCache() {
        if (this.box) {
            this.removeChild(this.box);
            //@ts-ignore
            this.box = undefined;
        }
        for (let key in this.boxCache) {
            //@ts-ignore
            this.boxCache[key].destroy();
            //@ts-ignore
            this.boxCache[key] = undefined;
            //@ts-ignore
            delete this.boxCache[key];
        }
    }
    destroySurrogate() {
        if (!this.surrogate) {
            return;
        }
        this.removeChild(this.surrogate);
        this.removeChild(this.surrogateMask);
        this.removeChild(this.surrogateHitbox);
        this.surrogate.destroy();
        this.surrogateMask.destroy();
        this.surrogateHitbox.destroy();
        //@ts-ignore
        this.surrogate = undefined;
        //@ts-ignore
        this.surrogateMask = undefined;
        //@ts-ignore
        this.surrogateHitbox = undefined;
    }
    applyRestriction() {
        if (this.restrictRegex.test(this.text)) {
            this.restrictValue = this.text;
        }
        else {
            this.text = this.restrictValue;
            this.domInput.setSelectionRange(this.selection[0], this.selection[1]);
        }
    }
    defaultBoxGenerator(styles) {
        styles.focused = styles.focused || styles.default;
        styles.disabled = styles.disabled || styles.default;
        return function (w, h, state) {
            //@ts-ignore
            let style = styles[state.toLowerCase()];
            let box = new PIXI.Graphics();
            if (style.rounded) {
                box.roundRect(0, 0, w, h, style.rounded);
            }
            else {
                box.rect(0, 0, w, h);
            }
            if (style.fill) {
                box.fill({ color: style.fill });
            }
            if (style.stroke) {
                box.setStrokeStyle({ width: style.stroke.width || 1, color: style.stroke.color || 0, alpha: style.stroke.alpha || 1 });
            }
            //box.closePath();
            return box;
        };
    }
}
export class TextInputStyle {
    constructor(input, box) {
        this.input = input;
        this.box = box;
    }
}
export class TextInputStyleInput {
    constructor() {
        this.fontFamily = "";
        this.fontSize = "";
        this.fontWeight = "";
        this.fontStyle = "";
        this.color = "";
        this.textDecoration = "";
        this.textTransform = "";
        this.textAlign = "";
        this.verticalAlign = "";
        this.lineHeight = "";
        this.letterSpacing = "";
        this.wordSpacing = "";
        this.textIndent = "";
        this.textShadow = "";
        this.whiteSpace = "";
        this.direction = "";
        this.padding = "";
        this.width = "";
        this.height = "";
        this.multiline = false;
    }
}
export class TextInputStyleBox {
    constructor(defaultStyle, focusedStyle, disabledStyle) {
        this.default = defaultStyle;
        this.focused = focusedStyle;
        this.disabled = disabledStyle;
    }
}
export class TextInputStyleBoxDetail {
    constructor(fill, rounded, stroke) {
        this.fill = fill;
        this.rounded = rounded;
        this.stroke = stroke;
    }
}
export class TextInputStyleBoxStroke {
    constructor(color, width, alpha) {
        this.color = color;
        this.width = width;
        this.alpha = alpha;
    }
}
class Rect {
}
class PreviousData {
}
class BoxCacheData {
}
