import { Point } from "pixi.js";
export default class FishCurve {
    pointMapY() {
        return [...this.pointY];
    }
    constructor(startPoint = new Point(0, 0), endPoint = new Point(255, 255), width = 256, height = 256) {
        /** 最大X值 */
        this.maxX = 255;
        /** 最小X值 */
        this.minX = 0;
        // /** 最大Y值 */
        this.maxY = 255;
        /** 最小Y值 */
        this.minY = 0;
        /** 采样宽度 */
        this.defaultWidth = 256;
        /** 采样高度 */
        this.defaultHeight = 256;
        this.pointArr = [];
        /** 线的颜色 */
        this.lineColor = 0xffffff;
        /** 点的颜色 */
        this.pointColor = 0xffffff;
        /** 点的大小 */
        this.pointSize = 6;
        this.pointArr = [new Point(startPoint.x, startPoint.y), new Point(endPoint.x, endPoint.y)];
        this.defaultWidth = width;
        this.defaultHeight = height;
        this.pointY = new Array(this.defaultWidth);
        this.sort();
        this.calcCurve(this.pointY);
    }
    /** 第一个点 */
    get startPoint() {
        let point = this.pointArr[0];
        return new Point(point.x, point.y);
    }
    /** 第一个点 */
    set startPoint(value) {
        let nextPoint = this.pointArr[1];
        let nextX = nextPoint.x;
        let point = this.pointArr[0];
        value = this.toIntPoint(value);
        let x = value.x;
        let y = value.y;
        y = Math.min(this.maxY, Math.max(this.minY, y));
        x = Math.min(this.maxX, nextX - 1, Math.max(this.minX, x));
        x = this.toInt(x);
        y = this.toInt(y);
        point.x = x;
        point.y = y;
        // this.sort();
        this.calcCurve(this.pointY);
    }
    /** 最后一个点 */
    get endPoint() {
        let point = this.pointArr[this.pointArr.length - 1];
        return new Point(point.x, point.y);
    }
    /** 最后一个点 */
    set endPoint(value) {
        let index = this.pointArr.length - 1;
        let prevPoint = this.pointArr[index - 1];
        let prevX = prevPoint.x;
        let point = this.pointArr[index];
        value = this.toIntPoint(value);
        let x = value.x;
        let y = value.y;
        y = Math.min(this.maxY, Math.max(this.minY, y));
        x = Math.min(this.maxX, Math.max(this.minX, prevX + 1, x));
        x = this.toInt(x);
        y = this.toInt(y);
        point.x = x;
        point.y = y;
        //this.sort();
        this.calcCurve(this.pointY);
    }
    get keyPointCount() {
        return this.pointArr.length;
    }
    setAllKeyPoint(points) {
        this.pointArr = points;
        this.sort();
        this.calcCurve(this.pointY);
    }
    resetCurveData() {
        this.pointArr = [new Point(0, 0), new Point(255, 255)];
        this.calcCurve(this.pointY);
    }
    /** 获取关键点 */
    getKeyPoint(index) {
        return new Point(this.pointArr[index].x, this.pointArr[index].y);
    }
    /** 设置关键点 */
    setKeyPoint(index, point) {
        this.pointArr[index].x = point.x;
        this.pointArr[index].y = point.y;
    }
    /** 添加关键节点 */
    addKeyPoints(points) {
        for (let i = 0; i < points.length; i++) {
            this.addKeySingePoint(points[i]);
        }
        this.sort();
        this.calcCurve(this.pointY);
    }
    /** 添加关键节点 */
    addKeyPoint(...points) {
        this.addKeyPoints(points);
    }
    addSingePoint(point) {
        point = this.toIntPoint(point);
        if (this.isRepeatPoint(point)) {
            console.error("点重复");
            return -1;
        }
        if (this.isOutRange(point)) {
            console.error("点在范围外");
            return -1;
        }
        this.pointArr.push(point);
        this.sort();
        return this.pointArr.indexOf(point);
    }
    /** 添加单个节点 */
    addKeySingePoint(point) {
        let index = this.addSingePoint(point);
        this.calcCurve(this.pointY);
        return index;
    }
    /** 移除关键节点 */
    removeKeyPoint(index) {
        if (index >= 0 && index < this.pointArr.length) {
            this.pointArr.splice(index, 1);
        }
    }
    /** 点排序 */
    sort() {
        this.pointArr.sort((a, b) => { return a.x - b.x; });
    }
    /** 是否重复,不能有两个x轴一样的点
     * @returns true 表示重复， false 表示没有重复
     */
    isRepeatPoint(point) {
        for (let i = 0; i < this.pointArr.length; i++) {
            if (point.x == this.pointArr[i].x) {
                return true;
            }
        }
        return false;
    }
    /** 点是否超出范围 */
    isOutRange(point) {
        if (point.y < this.minY || point.y > this.maxY) { //Y轴超出范围
            return true;
        }
        if (point.x < this.startPoint.x || point.x > this.endPoint.x) {
            return true;
        }
        return false;
    }
    /** 移动点 */
    movePoint(x, y, index) {
        let pointOut = false;
        let point = this.pointArr[index];
        if (index == 0) { //第一个点
            let nextPoint = this.pointArr[index + 1];
            let nextX = nextPoint.x;
            // let x = point.x + dx;
            // let y = point.y + dy;
            y = Math.min(this.maxY, Math.max(this.minY, y));
            x = Math.min(this.maxX, nextX - 1, Math.max(this.minX, x));
            x = this.toInt(x);
            y = this.toInt(y);
            point.x = x;
            point.y = y;
            pointOut = true;
        }
        else if (index == this.pointArr.length - 1) { //最后一个点
            let prevPoint = this.pointArr[index - 1];
            let prevX = prevPoint.x;
            // let x = point.x + dx;
            // let y = point.y + dy;
            y = Math.min(this.maxY, Math.max(this.minY, y));
            x = Math.min(this.maxX, Math.max(this.minX, prevX + 1, x));
            x = this.toInt(x);
            y = this.toInt(y);
            point.x = x;
            point.y = y;
            pointOut = true;
        }
        else {
            let nextPoint = this.pointArr[index + 1];
            let prevPoint = this.pointArr[index - 1];
            let nextX = nextPoint.x;
            let prevX = prevPoint.x;
            if ((x > nextX - 1 || x < prevX + 1) || (y > this.maxY || y < this.minY)) { //移出范围
                //this.moveRemovePoint();
                this.removeKeyPoint(index);
                pointOut = false;
            }
            else {
                y = Math.min(this.maxY, Math.max(this.minY, y));
                x = Math.min(this.maxX, Math.max(this.minX, prevX + 1, x));
                point.x = x;
                point.y = y;
                pointOut = true;
            }
        }
        this.calcCurve(this.pointY);
        return pointOut;
    }
    /** 计算曲线 */
    calcCurve(output_y) {
        //if count of control points is less than 2, return linear output
        if (this.pointArr.length < 2) {
            for (let i = 0; i < this.defaultWidth; ++i) {
                output_y[i] = this.maxX - i;
            }
            return 0;
        }
        //if count of control points is 2, return linear output
        if (this.pointArr.length == 2) {
            let point1 = this.pointArr[0];
            let point2 = this.pointArr[1];
            let delta_y = 0;
            if (point2.x != point1.x)
                delta_y = (point2.y - point1.y) * 1.0 / (point2.x - point1.x);
            //create output
            for (let i = 0; i < this.defaultWidth; ++i) {
                if (i < point1.x) {
                    output_y[i] = point1.y;
                }
                else if (i >= point1.x && i < point2.x) {
                    output_y[i] = Math.min(Math.max(point1.y + delta_y * (i - point1.x), this.minY), this.maxY);
                }
                else {
                    output_y[i] = point2.y;
                }
            }
            return 0;
        }
        //the count of control points is greater than 2,  create spline line
        let n = this.pointArr.length; //count of points
        //create array of x-coordinate and y-coordinate of control points
        let x = new Array(n);
        let y = new Array(n);
        let start_point = null;
        let end_point = null;
        let iter = this.startPoint;
        for (let k = 0; k < this.pointArr.length; ++k) {
            iter = this.pointArr[k];
            if (k == 0)
                start_point = iter;
            x[k] = iter.x - start_point.x;
            y[k] = iter.y;
            end_point = iter;
        }
        //if start_point or end_point is invalid
        if (start_point == null || end_point == null || start_point == end_point) {
            for (let i = 0; i < this.defaultWidth; ++i) {
                output_y[i] = this.maxX - i;
            }
            return 1;
        }
        //create array of x-coordinate of output points
        let m = end_point.x - start_point.x;
        let t = new Array(m); //array of x-coordinate of output points
        let z = new Array(m); //array of y-coordinate of output points
        //initialize array of x-coordinate
        for (let i = 0; i < m; ++i) {
            t[i] = i;
        }
        FishCurve.spline(x, y, n, t, m, z);
        //create output
        for (let i = 0; i < this.defaultWidth; ++i) {
            if (i < start_point.x) {
                output_y[i] = start_point.y;
            }
            else if (i >= start_point.x && i < end_point.x) {
                output_y[i] = Math.min(Math.max(z[i - start_point.x], this.minY), this.maxY);
            }
            else {
                output_y[i] = end_point.y;
            }
        }
        return 0;
    }
    /** 转整数 */
    toInt(num) {
        return Math.round(num);
    }
    /** 转整数点 */
    toIntPoint(point) {
        let newPoint = new Point();
        newPoint.x = this.toInt(point.x);
        newPoint.y = this.toInt(point.y);
        return newPoint;
    }
    static spline(x, y, n, t, m, z) {
        let dy = new Array(n);
        for (let i = 0; i < dy.length; i++) {
            dy[i] = 0;
        }
        dy[0] = -0.5;
        let ddy = new Array(n);
        for (let i = 0; i < ddy.length; i++) {
            ddy[i] = 0;
        }
        let h1 = 0;
        let s = new Array(n);
        let h0 = x[1] - x[0];
        s[0] = 3.0 * (y[1] - y[0]) / (2.0 * h0) - ddy[0] * h0 / 4.0;
        for (let j = 1; j <= n - 2; ++j) {
            h1 = x[j + 1] - x[j];
            let alpha = h0 / (h0 + h1);
            let beta = (1.0 - alpha) * (y[j] - y[j - 1]) / h0;
            beta = 3.0 * (beta + alpha * (y[j + 1] - y[j]) / h1);
            dy[j] = -alpha / (2.0 + (1.0 - alpha) * dy[j - 1]);
            s[j] = (beta - (1.0 - alpha) * s[j - 1]);
            s[j] = s[j] / (2.0 + (1.0 - alpha) * dy[j - 1]);
            h0 = h1;
        }
        dy[n - 1] = (3.0 * (y[n - 1] - y[n - 2]) / h1 + ddy[n - 1] * h1 / 2.0 - s[n - 2]) / (2.0 + dy[n - 2]);
        for (let j = n - 2; j >= 0; --j) {
            dy[j] = dy[j] * dy[j + 1] + s[j];
        }
        for (let j = 0; j <= n - 2; ++j) {
            s[j] = x[j + 1] - x[j];
        }
        for (let j = 0; j <= n - 2; ++j) {
            h1 = s[j] * s[j];
            ddy[j] = 6.0 * (y[j + 1] - y[j]) / h1 - 2.0 * (2.0 * dy[j] + dy[j + 1]) / s[j];
        }
        h1 = s[n - 2] * s[n - 2];
        ddy[n - 1] = 6.0 * (y[n - 2] - y[n - 1]) / h1 + 2.0 * (2.0 * dy[n - 1] + dy[n - 2]) / s[n - 2];
        let g = 0.0;
        for (let i = 0; i <= n - 2; i++) {
            h1 = 0.5 * s[i] * (y[i] + y[i + 1]);
            h1 = h1 - s[i] * s[i] * s[i] * (ddy[i] + ddy[i + 1]) / 24.0;
            g = g + h1;
        }
        for (let j = 0; j <= m - 1; j++) {
            let i;
            if (t[j] >= x[n - 1]) {
                i = n - 2;
            }
            else {
                i = 0;
                while (t[j] > x[i + 1]) {
                    i = i + 1;
                }
            }
            h1 = (x[i + 1] - t[j]) / s[i];
            h0 = h1 * h1;
            z[j] = (3.0 * h0 - 2.0 * h0 * h1) * y[i];
            z[j] = z[j] + s[i] * (h0 - h0 * h1) * dy[i];
            h1 = (t[j] - x[i]) / s[i];
            h0 = h1 * h1;
            z[j] = z[j] + (3.0 * h0 - 2.0 * h0 * h1) * y[i + 1];
            z[j] = z[j] - s[i] * (h0 - h0 * h1) * dy[i + 1];
        }
        return (g);
    }
    /** 获取整数Y值 */
    getIntY(x) {
        return Math.round(this.getY(x));
    }
    /** 获取Y的数值 */
    getY(x) {
        return this.pointY[Math.round(x)];
    }
    get isDefault() {
        if (this.keyPointCount != 2) {
            return false;
        }
        let point = this.startPoint;
        if (point.x != this.minX) {
            return false;
        }
        if (point.y != this.minY) {
            return false;
        }
        point = this.endPoint;
        if (point.x != this.maxX) {
            return false;
        }
        if (point.y != this.maxY) {
            return false;
        }
        return true;
    }
}
