import ChromosomeGroup from "./ChromosomeGroup";
import { ITEM_REORDER } from "../Event/ItemEventName";
import Container from "../Core/Component/Container";
export default class ChromosomeRow extends Container {
    //constructor(ids: Array<number>, width: number, height: number, padding: number, canvas: KaryotypeCanvas) {
    constructor(ids, width, height, padding, canvas) {
        super();
        this.MIN_MARGIN = 5;
        this.groups = [];
        this.hiatusCount = 0;
        this.canvas = canvas;
        this.ids = ids;
        this.padding = padding;
        let colWidth = (width - padding * 2) / 8;
        let colMargin = colWidth / 6;
        let posX = padding;
        this._itemOriginalWidth = colWidth;
        this.originalWidth = width;
        for (let i = 0; i < ids.length; i++) {
            if (ids[i] != -1) {
                let col = new ChromosomeGroup(ids[i], colWidth, height, this, canvas);
                this.addChild(col);
                col.x = posX;
                col.y = 0;
                //col.addChildAt(new PIXI.Graphics().rect(0, 0, colWidth, this.groupHeight).fill({ color: 0x00ff00 }), 0);` 
                this.groups.push(col);
                //col.createItem([`./assets/${ids[i]}.png`, `./assets/${ids[i]}.png`]);
            }
            else {
                this.hiatusCount++;
            }
            posX = posX + colWidth + colMargin;
        }
        this.canvas.on(ITEM_REORDER, this.onReorder, this);
    }
    get itemOriginalWidth() { return (this.canvas.pixi.screen.width - this.padding * 2) / 8; /*this._itemOriginalWidth;*/ }
    getGroups() {
        return Array.from(this.groups);
    }
    resetSize(width, height, padding, resetOriginalWidth = true) {
        this.padding = padding;
        let sumWidth = 0;
        for (let i = 0; i < this.groups.length; i++) {
            sumWidth += this.groups[i].boundsArea.width;
        }
        if (this.hiatusCount == 0) {
            let tempPos = padding;
            let margin = (width - padding * 2 - sumWidth) / (this.ids.length - 1);
            for (let i = 0; i < this.groups.length; i++) {
                this.groups[i].x = tempPos;
                this.groups[i].boundsArea.height = height;
                tempPos += (this.groups[i].boundsArea.width + margin);
            }
        }
        else {
            let hiatusCount = 0;
            let posX = padding;
            let margin = (width - padding * 2 - sumWidth - this.itemOriginalWidth) / (this.ids.length - 1);
            for (let i = 0; i < this.ids.length; i++) {
                if (this.ids[i] != -1) {
                    this.groups[i - hiatusCount].x = posX;
                    posX = posX + this.groups[i - hiatusCount].boundsArea.width + margin;
                }
                else {
                    hiatusCount++;
                    posX = posX + this.itemOriginalWidth + margin;
                }
            }
        }
    }
    recoder(isNeedResetItemSize) {
        let sumWidth = this.hiatusCount * this.itemOriginalWidth;
        let maxSumWidth = this.canvas.pixi.screen.width - this.padding * 2 - this.MIN_MARGIN * (this.ids.length - 1);
        for (let i = 0; i < this.groups.length; i++) {
            this.groups[i].boundsArea.height = this.height;
            this.groups[i].rechangeSize(isNeedResetItemSize);
            sumWidth += this.groups[i].boundsArea.width;
        }
        if (sumWidth < maxSumWidth) {
            let colMargin = (this.canvas.rowWidth - this.padding * 2 - sumWidth) / (this.ids.length - 1);
            let posX = this.padding;
            let hiatusCount = 0;
            for (let i = 0; i < this.ids.length; i++) {
                if (this.ids[i] != -1) {
                    this.groups[i - hiatusCount].x = posX;
                    posX = posX + this.groups[i - hiatusCount].boundsArea.width + colMargin;
                }
                else {
                    hiatusCount++;
                    posX = posX + this.itemOriginalWidth + colMargin;
                }
            }
            this.boundsArea.width = posX + this.padding - colMargin;
        }
        else {
            if (this.hiatusCount > 0) {
                let hiatusWidth = this.itemOriginalWidth;
                let posX = this.padding;
                let hiatusCount = 0;
                for (let i = 0; i < this.ids.length; i++) {
                    if (this.ids[i] != -1) {
                        this.groups[i - hiatusCount].x = posX;
                        posX = posX + this.groups[i - hiatusCount].boundsArea.width + this.MIN_MARGIN;
                    }
                    else {
                        hiatusCount++;
                        posX = posX + hiatusWidth + this.MIN_MARGIN;
                    }
                }
                this.boundsArea.width = posX + this.padding;
                /*if(sumWidth < this.maxSumWidth + this.itemOriginalWidth - this.MIN_MARGIN * 2) {
                    let hiatusWidth = (this.maxSumWidth + this.itemOriginalWidth - this.MIN_MARGIN * 2) - sumWidth;
                    let posX = this.padding;
                    let hiatusCount = 0;
                    for(let i = 0; i < this.ids.length; i++) {
                        if(this.ids[i] != -1) {
                            this.groups[i - hiatusCount].x = posX;
                            posX = posX + this.groups[i - hiatusCount].width + this.MIN_MARGIN;
                        }
                        else {
                            hiatusCount++;
                            posX = posX + hiatusWidth + this.MIN_MARGIN * 3;
                        }
                    }
                    this.boundsArea.width = posX + this.padding;
                    console.log(22222);
                }
                else {
                    let posX = this.padding;
                    let hiatusCount = 0;
                    for(let i = 0; i < this.ids.length; i++) {
                        if(this.ids[i] != -1) {
                            this.groups[i - hiatusCount].x = posX;
                            posX = posX + this.groups[i - hiatusCount].width + this.MIN_MARGIN;
                        }
                        else {
                            hiatusCount++;
                            posX = posX + this.MIN_MARGIN * 3;
                        }
                    }
                    this.boundsArea.width = posX - this.MIN_MARGIN + this.padding;
                    console.log(33333);
                }*/
            }
            else {
                let posX = this.padding;
                for (let i = 0; i < this.ids.length; i++) {
                    this.groups[i].x = posX;
                    posX = posX + this.groups[i].boundsArea.width + this.MIN_MARGIN;
                }
                this.boundsArea.width = posX - this.MIN_MARGIN + this.padding;
            }
        }
    }
    onReorder(item) {
        if (this.ids.indexOf(item.id) == -1) {
            return;
        }
        //this.recoder();
        this.canvas.changeWidth(false);
    }
    onDestroy() {
        super.onDestroy();
        this._itemOriginalWidth = undefined;
        this.canvas = undefined;
        this.ids = undefined;
        for (let i = 0; i < this.groups.length; i++) {
            this.groups[i] = undefined;
        }
        this.groups = undefined;
        this.hiatusCount = undefined;
        this.padding = undefined;
        this.originalWidth = undefined;
    }
}
