export const LOCALIZATION_DATA = {
    PATTERN_IMAGE: ["显示模式图", "Pattern Image"],
    UNDO: ["撤销", "Undo"],
    REDO: ["还原", "Redo"],
    SHOW_OUTLINE: ["显示边线", "Show Outline"],
    SHOW_ANNOTATIONS: ["显示标注", "Show Annotations"],
    SHOW_NUMBER: ["显示编号", "Show Number"],
    SHOW_ORIGINAL_IMAGE: ["显示去背景图", "Show Original Image"],
    SHOW_IDIOGRAM: ["显示模式图", "Show Idiogram"],
    ZOOM_IN: ["放大", "Zoom In"],
    ZOOM_OUT: ["缩小", "Zoom Out"],
};
