import * as PIXI from 'pixi.js';
import { LOCALIZATION_DATA } from '../Data/LocalizationData';
export default class Menu extends PIXI.Container {
    constructor(canvas, mousePosition, isShowIdiogram) {
        super();
        this.boundsArea = new PIXI.Rectangle(0, 0, canvas.pixi.screen.width, canvas.pixi.screen.height);
        this.hitArea = this.boundsArea;
        canvas.pixi.stage.addChild(this);
        this.x = 0;
        this.y = 0;
        this.interactive = true;
        //let textStr = isShowIdiogram ? "√ 显示模式图" : "显示模式图";
        let color = isShowIdiogram ? 0x009CFF : 0x000000;
        let menu = new PIXI.Container();
        this.addChild(menu);
        let bg = new PIXI.Graphics();
        menu.addChild(bg);
        let text = new PIXI.Text({
            text: LOCALIZATION_DATA.PATTERN_IMAGE[canvas.canvasMain.lang] /*textStr*/,
            style: {
                fontFamily: 'Arial',
                fontSize: 20,
                fill: color,
                align: 'center',
            }
        });
        menu.addChild(text);
        menu.boundsArea = new PIXI.Rectangle(0, 0, text.width + 20, text.height + 20);
        text.x = 10;
        text.y = 10;
        bg.setStrokeStyle({ color: 0xFFFFFF, width: 1, alpha: 1 }); //0xAAAAAA
        bg.rect(0, 0, menu.boundsArea.width, menu.boundsArea.height).fill({ color: 0xFFFFFF, alpha: 1 }); //0xCCCCCC
        bg.stroke();
        let local = this.toLocal(mousePosition);
        menu.position = local;
        text.interactive = true;
        text.on("mousedown", (e) => {
            e.stopPropagation();
            isShowIdiogram = !isShowIdiogram;
            //textStr = isShowIdiogram ? "√ 显示模式图" : "显示模式图";
            let color = isShowIdiogram ? 0x009CFF : 0x000000;
            //text.text = textStr;
            text.style.fill = color;
            menu.boundsArea = new PIXI.Rectangle(0, 0, text.width + 20, text.height + 20);
            text.x = 10;
            text.y = 10;
            bg.clear();
            bg.rect(0, 0, menu.boundsArea.width, menu.boundsArea.height).fill({ color: 0xFFFFFF, alpha: 1 });
            bg.stroke();
            this.emit("idiogram", isShowIdiogram);
        }, this);
        this.on("mousedown", () => { this.destroy(true); }, this);
    }
}
