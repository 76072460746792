export default class MemoData {
    /**
     * 备忘录数据
     * @param before 修改前数据
     * @param after 修改后数据
     * @param changeData 变更数据，按字符串下标记录本条备忘录修改了什么内容，1为修改了，0为未修改，各下标依次代表 染色体编号、染色体序号、变换角度、着丝点、旋转角度、Y轴坐标(百分比位置)、原始点的坐标集合、变换后的点的坐标集合、完全变换后的边界信息、位移变换前的边界信息、图像数据、是否新建、染色体计数、染色体分割、图像增强、标注
     */
    constructor(before, after, type, changeData) {
        this._beforeData = before;
        this._afterData = after;
        this._type = type;
        this._changeData = [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];
        for (let i = 0; i < changeData.length; i++) {
            if (changeData[i] === "1") {
                this._changeData[i] = true;
            }
            else {
                this._changeData[i] = false;
            }
        }
    }
    get beforeData() { return this._beforeData; }
    get afterData() { return this._afterData; }
    get type() { return this._type; }
    /** 染色体编号、染色体序号、变换角度、着丝点、旋转角度、Y轴坐标(百分比位置)、原始点的坐标集合、变换后的点的坐标集合、完全变换后的边界信息、位移变换前的边界信息、图像数据、是否新建、染色体计数、染色体分割、图像增强、标注 */
    get changeData() {
        let temp = this._changeData.slice();
        return temp;
    }
}
