import * as PIXI from "pixi.js";
import PIXICanvas, { CanvasMode } from "../Core/Canvas/PIXICanvas";
import ChromosomeRow from "../Component/ChromosomeRow";
import { ITEM_ADDED, ITEM_EDITED_IN_KARYOTYPE_PAGE, ITEM_IMAGE_LOAD_COMPLETE, ITEM_MOVE_DRAG_END, ITEM_MOVE_IN, ITEM_MOVE_OUT, ITEM_REMOVED, ITEM_SELECTED } from "../Event/ItemEventName";
import BoundsData from "../Core/Data/BoundsData";
import { OperationType } from "../Core/OperationStepSystem";
import MarkEditorCanvas, { MarkMode } from "./MarkEditorCanvas";
import { SYSTEM_GROUP_CHANGED, SYSTEM_ITEM_LOAD_COMPLETE, SYSTEM_ITEM_REMOVED, SYSTEM_ITEM_SELECTED_IN_KARYOTYPE, SYSTEM_ITEM_SELECTED_IN_ORIGINAL_IMAGE } from "../Core/Event/SystemEventName";
/**核型分析canvas */
class KaryotypeCanvas extends PIXICanvas {
    constructor() {
        super(...arguments);
        this.rowGroups = [];
        this.chromosomeGroups = [];
        this.addedChromosomeIds = [];
        this.mouseInGroupId = -1;
        this.markEditorCanvas = new MarkEditorCanvas();
        this.operationMode = OperationMode.NoneMode;
        this.loadCount = 0;
        this._rowWidth = 0;
        this._maxEditorPenSize = 16;
        this._minEditorPenSize = 3;
        this._editorPenSizeInterval = 1;
        this._editorPenSize = 5;
        this._isSaveGroupImage = false;
        this.curMode = CanvasMode.None;
    }
    get rowWidth() { return this._rowWidth; }
    get editorPenSize() { return this._editorPenSize; }
    get isShowMark() { return this.markEditorCanvas.visible; }
    set isShowMark(value) { this.markEditorCanvas.visible = value; }
    set isSaveGroupImage(value) { this._isSaveGroupImage = value; }
    onInit(width, height) {
        this.operationMode = OperationMode.NoneMode;
        this._rowWidth = width;
        this.group = new PIXI.Container();
        this.stage.addChild(this.group);
        this.group.boundsArea = new PIXI.Rectangle(0, 0, width, height);
        let groupHeight = height / 5;
        let groupMargin = groupHeight / 3;
        let groupGap = groupMargin / 3;
        let posY = groupMargin;
        for (let i = 0; i < 4; i++) {
            let row = new ChromosomeRow(KaryotypeCanvas.ids[i], width, groupHeight, groupMargin, this);
            this.group.addChild(row);
            row.boundsArea = new PIXI.Rectangle(0, 0, width, groupHeight);
            row.x = 0;
            row.y = posY;
            //row.addChild(new PIXI.Graphics().rect(0, 0, width, this.groupHeight).fill({ color: 0xff0000 }));
            posY = posY + groupGap + groupHeight;
            this.rowGroups.push(row);
            this.chromosomeGroups = this.chromosomeGroups.concat(row.getGroups());
        }
        this.changeWidth(false);
        this.on(ITEM_SELECTED, this.onItemSelected, this);
        this.on(ITEM_MOVE_IN, this.onItemMoveIn, this);
        this.on(ITEM_MOVE_OUT, this.onItemMoveOut, this);
        this.on(ITEM_MOVE_DRAG_END, this.onItemDragEnd, this);
        this.on(ITEM_IMAGE_LOAD_COMPLETE, this.loadImgComplete, this);
        this.canvasMain.on(SYSTEM_ITEM_REMOVED, this.onItemRemoved, this);
        this.canvasMain.on(SYSTEM_ITEM_SELECTED_IN_ORIGINAL_IMAGE, this.focusWithItem, this);
        this.stage.addChild(this.markEditorCanvas);
        this.markEditorCanvas.init(this.stage);
        let scale = this.isMainCanvas ? 1 : this.canvasMain.subCanvasWidth / this.canvasMain.mainCanvasWidth;
        this.markEditorCanvas.refreshCanvas(this.isMainCanvas, scale, new PIXI.Point(0, 0), width, height);
        this.markEditorCanvas.resize(width, height);
        this.markEditorCanvas.setMode(-1);
        this.maskText.text = "暂无核型图\n请先进行核型分析";
        this.showMask(true);
        // this.editorGraphics = new PIXI.Graphics();
        // this.stage.addChild(this.editorGraphics);
        // this.editorGraphics.setStrokeStyle({ color: 0xff0000, width: 1 });
        // this.setPenSize(this._editorPenSize);
        // this.editorGraphics.interactive = false;
        // this.editorGraphics.visible = false;
        // this.stage.on("mousemove", (event) => {
        //     if(this.operationMode != OperationMode.EditMode) { return; }
        //     let local = this.stage.toLocal(event.global);
        //     this.editorGraphics.x = local.x;
        //     this.editorGraphics.y = local.y;
        // }, this);
    }
    setData(boundsDataArr) {
        //this.showMask(false);
        let dataArr = [];
        this.loadCount = 0;
        this.addedChromosomeIds = [];
        for (let i = 1; i < 26; i++) {
            dataArr[i] = [];
        }
        for (let i = 0; i < boundsDataArr.length; i++) {
            let data = boundsDataArr[i];
            dataArr[data.id][data.index] = data;
            this.loadCount++;
            if (this.addedChromosomeIds.indexOf(data.id) < 0) {
                this.addedChromosomeIds.push(data.id);
            }
        }
        for (let i = 1; i < dataArr.length; i++) {
            if (dataArr[i].length > 0) {
                let group = this.chromosomeGroups.find((item) => { return item.id == i; });
                group.createItem(dataArr[i]);
            }
        }
    }
    getMarkData() {
        return JSON.stringify(this.markEditorCanvas.getData());
    }
    setMarkData(data) {
        //JSON.stringify(this.markEditorCanvas.setData(JSON.parse(data)));
        this.markEditorCanvas.setData(JSON.parse(data));
    }
    increasePenSize() {
        if (this.curMode != CanvasMode.OutlineDecorate)
            return;
        this._editorPenSize += this._editorPenSizeInterval;
        if (this._editorPenSize > this._maxEditorPenSize) {
            this._editorPenSize = this._maxEditorPenSize;
        }
        this.setPenSize(this._editorPenSize);
    }
    decreasePenSize() {
        if (this.curMode != CanvasMode.OutlineDecorate)
            return;
        this._editorPenSize -= this._editorPenSizeInterval;
        if (this._editorPenSize < this._minEditorPenSize) {
            this._editorPenSize = this._minEditorPenSize;
        }
        this.setPenSize(this._editorPenSize);
    }
    setPenSize(value) {
        this.rootCanvas.style.cursor = this.getCursorCircle(value);
        // this._editorPenSize = value;
        // this.editorGraphics.clear();
        // this.editorGraphics.moveTo(this._editorPenSize, 0);
        // for (let angle = 10; angle <= 360; angle += 10) {
        //     let radian = angle * Math.PI / 180;
        //     let x = this._editorPenSize * Math.cos(radian);
        //     let y = this._editorPenSize * Math.sin(radian);
        //     this.editorGraphics.lineTo(x, y);
        // }
        // this.editorGraphics.stroke();
    }
    showIdiogram(value) {
        for (let i = 0; i < this.chromosomeGroups.length; i++) {
            if (this.chromosomeGroups[i].id != 25) {
                this.chromosomeGroups[i].setIdiogram(value);
            }
        }
    }
    onClear() {
        this.rowGroups = [];
        this.chromosomeGroups = [];
        this.seletedItem = undefined;
        this.mouseInGroupId = -1;
        this.markEditorCanvas = new MarkEditorCanvas();
        this.operationMode = OperationMode.NoneMode;
        this.off(ITEM_SELECTED, this.onItemSelected, this);
        this.off(ITEM_MOVE_IN, this.onItemMoveIn, this);
        this.off(ITEM_MOVE_OUT, this.onItemMoveOut, this);
        this.off(ITEM_MOVE_DRAG_END, this.onItemDragEnd, this);
        this.off(ITEM_IMAGE_LOAD_COMPLETE, this.loadImgComplete, this);
        this.canvasMain.off(SYSTEM_ITEM_REMOVED, this.onItemRemoved, this);
        this.canvasMain.off(SYSTEM_ITEM_SELECTED_IN_ORIGINAL_IMAGE, this.focusWithItem, this);
    }
    onDestroy() {
        this.off(ITEM_SELECTED, this.onItemSelected, this);
        this.off(ITEM_MOVE_IN, this.onItemMoveIn, this);
        this.off(ITEM_MOVE_OUT, this.onItemMoveOut, this);
        this.off(ITEM_MOVE_DRAG_END, this.onItemDragEnd, this);
        this.off(ITEM_IMAGE_LOAD_COMPLETE, this.loadImgComplete, this);
        this.canvasMain.off(SYSTEM_ITEM_REMOVED, this.onItemRemoved, this);
        this.canvasMain.off(SYSTEM_ITEM_SELECTED_IN_ORIGINAL_IMAGE, this.focusWithItem, this);
    }
    onResize(width, height) {
        var _a;
        this.operationMode = OperationMode.NoneMode;
        (_a = this.seletedItem) === null || _a === void 0 ? void 0 : _a.switchToNoneMode();
        this.seletedItem = undefined;
        this._rowWidth = width;
        let groupHeight = height / 5;
        let groupMargin = groupHeight / 3;
        let groupGap = groupMargin / 3;
        let posY = groupMargin;
        for (let i = 0; i < this.rowGroups.length; i++) {
            this.rowGroups[i].boundsArea.width = width;
            this.rowGroups[i].boundsArea.height = groupHeight;
            this.rowGroups[i].x = 0;
            this.rowGroups[i].y = posY;
            posY = posY + groupGap + groupHeight;
            this.rowGroups[i].resetSize(width, groupHeight, groupMargin);
        }
        this.changeWidth(true);
        let scale = this.isMainCanvas ? 1 : this.canvasMain.subCanvasWidth / this.canvasMain.mainCanvasWidth;
        this.markEditorCanvas.refreshCanvas(this.isMainCanvas, scale, new PIXI.Point(0, 0), width, height);
        this.markEditorCanvas.resize(width, height);
    }
    setMarkMode(value) {
        this.markEditorCanvas.setMode(value);
    }
    deleteMark() {
        this.markEditorCanvas.removeItem(undefined);
    }
    undo(data) {
        if (data.type == OperationType.Transform) {
            let beforeData = BoundsData.createFromString(data.beforeData);
            let afterData = BoundsData.createFromString(data.afterData);
            this.canvasMain.getDataURL(beforeData);
            this.canvasMain.getDataURL(afterData);
            if (data.changeData[0]) {
                let group = this.chromosomeGroups.find((group) => { return group.id == afterData.id; });
                let item = group.getItem(afterData.index);
                let targetGroup = this.chromosomeGroups.find((group) => { return group.id == beforeData.id; });
                targetGroup.resetItemIndex();
                targetGroup === null || targetGroup === void 0 ? void 0 : targetGroup.recoverItem(item, afterData, beforeData, true);
                group.resetItemIndex();
                item === null || item === void 0 ? void 0 : item.setData(beforeData);
                this.changeWidth(false);
                this.canvasMain.emit(SYSTEM_GROUP_CHANGED, afterData.id);
            }
            else {
                let group = this.chromosomeGroups.find((group) => { return group.id == beforeData.id; });
                let item;
                if (data.changeData[1]) {
                    item = group.getItem(afterData.index);
                    group === null || group === void 0 ? void 0 : group.recoverItem(item, afterData, beforeData, false);
                    this.changeWidth(false);
                }
                else {
                    item = group.getItem(beforeData.index);
                }
                item.setData(beforeData);
            }
        }
        else if (data.type == OperationType.Decoration) {
            let beforeData = BoundsData.createFromString(data.beforeData);
            this.canvasMain.getDataURL(beforeData);
            let group = this.chromosomeGroups.find((group) => { return group.id == beforeData.id; });
            let item = group.getItem(beforeData.index);
            item === null || item === void 0 ? void 0 : item.setData(beforeData);
        }
        else if (data.type == OperationType.Annotation) {
            let markData = JSON.parse(data.beforeData);
            if (markData.canvasType != this.canvasType) {
                return;
            }
            this.markEditorCanvas.setData(markData.data);
        }
        else if (data.type == OperationType.Segmentation) { //切割时，先改后加，还原时，先删后改。顺序与不能乱
            let beforeData = BoundsData.createFromString(data.beforeData);
            this.canvasMain.getDataURL(beforeData);
            // let addObj = JSON.parse(JSON.parse(data.afterData)[1]);
            // let addStr = JSON.stringify(addObj);
            let addStr = JSON.parse(data.afterData)[1];
            let addData = BoundsData.createFromString(addStr);
            this.canvasMain.emit(ITEM_REMOVED, addData); // 先删除
            let group = this.chromosomeGroups.find((group) => { return group.id == beforeData.id; }); //后改原本数据
            let item = group.getItem(beforeData.index);
            item.setData(beforeData);
            this.canvasMain.emit(ITEM_EDITED_IN_KARYOTYPE_PAGE, item.data);
        }
    }
    redo(data) {
        if (data.type == OperationType.Transform) {
            let beforeData = BoundsData.createFromString(data.beforeData);
            let afterData = BoundsData.createFromString(data.afterData);
            this.canvasMain.getDataURL(afterData);
            if (data.changeData[0]) {
                let group = this.chromosomeGroups.find((group) => { return group.id == beforeData.id; });
                let item = group.getItem(beforeData.index);
                let targetGroup = this.chromosomeGroups.find((group) => { return group.id == afterData.id; });
                targetGroup.resetItemIndex();
                targetGroup === null || targetGroup === void 0 ? void 0 : targetGroup.recoverItem(item, beforeData, afterData, true);
                group.resetItemIndex();
                item === null || item === void 0 ? void 0 : item.setData(afterData);
                this.changeWidth(false);
                this.canvasMain.emit(SYSTEM_GROUP_CHANGED, beforeData.id);
            }
            else {
                let group = this.chromosomeGroups.find((group) => { return group.id == afterData.id; });
                let item;
                if (data.changeData[1]) {
                    item = group.getItem(beforeData.index);
                    group === null || group === void 0 ? void 0 : group.recoverItem(item, beforeData, afterData, false);
                    this.changeWidth(false);
                }
                else {
                    item = group.getItem(afterData.index);
                }
                item === null || item === void 0 ? void 0 : item.setData(afterData);
            }
        }
        else if (data.type == OperationType.Decoration) {
            let afterData = BoundsData.createFromString(data.afterData);
            this.canvasMain.getDataURL(afterData);
            let group = this.chromosomeGroups.find((group) => { return group.id == afterData.id; });
            let item = group.getItem(afterData.index);
            item === null || item === void 0 ? void 0 : item.setData(afterData);
        }
        else if (data.type == OperationType.Annotation) {
            let markData = JSON.parse(data.afterData);
            if (markData.canvasType != this.canvasType) {
                return;
            }
            this.markEditorCanvas.setData(markData.data);
        }
        else if (data.type == OperationType.Segmentation) { //切割时，先改后加，还原时，先删后改。顺序与不能乱
            // let editObj = JSON.parse(JSON.parse(data.afterData)[0]);
            // let editStr = JSON.stringify(editObj);
            let editStr = JSON.parse(data.afterData)[0];
            let editData = BoundsData.createFromString(editStr);
            this.canvasMain.getDataURL(editData);
            // let addObj = JSON.parse(JSON.parse(data.afterData)[1]);
            // let addStr = JSON.stringify(addObj);
            let addStr = JSON.parse(data.afterData)[1];
            let addData = BoundsData.createFromString(addStr);
            this.canvasMain.getDataURL(addData);
            let group = this.chromosomeGroups.find((group) => { return group.id == editData.id; }); //先改原数据
            let item = group.getItem(editData.index);
            item.setData(editData);
            this.canvasMain.emit(ITEM_EDITED_IN_KARYOTYPE_PAGE, item.data);
            this.canvasMain.emit(ITEM_ADDED, addData); //先后添加数据
        }
    }
    changeWidth(isNeedResetItemSize) {
        let maxWidth = 0;
        for (let i = 0; i < this.rowGroups.length; i++) {
            this.rowGroups[i].recoder(isNeedResetItemSize);
            maxWidth = Math.max(maxWidth, this.rowGroups[i].boundsArea.width);
        }
        this.group.boundsArea.width = maxWidth;
        //this.stage.boundsArea.width = maxWidth;
        this.stage.resize(maxWidth, this.stage.boundsArea.height);
        let groupHeight = this.rowGroups[0].boundsArea.height;
        let groupMargin = groupHeight / 3;
        for (let i = 0; i < this.rowGroups.length; i++) {
            this.rowGroups[i].boundsArea.width = maxWidth;
            this.rowGroups[i].resetSize(maxWidth, groupHeight, groupMargin, false);
        }
    }
    setMode(value) {
        var _a, _b;
        //this.editorGraphics.visible = false;
        this.curMode = value;
        this.rootCanvas.style.cursor = "auto";
        if (value == CanvasMode.OutlineDecorate) {
            this._editorPenSize = 5;
            this.setMarkMode(MarkMode.None);
            this.operationMode = OperationMode.EditMode;
            //this.editorGraphics.visible = true;
            this.setPenSize(this._editorPenSize);
            if (this.seletedItem) {
                this.seletedItem.switchToEditMode();
            }
        }
        else if (value == CanvasMode.Centromere) {
            this.setMarkMode(MarkMode.None);
            this.operationMode = OperationMode.CentromereMode;
            if (this.seletedItem) {
                this.seletedItem.switchToCentromereMode();
            }
        }
        else if (value == CanvasMode.ArrowEditor) {
            this.setMarkMode(MarkMode.Arrow);
            this.operationMode = OperationMode.NoneMode;
            (_a = this.seletedItem) === null || _a === void 0 ? void 0 : _a.switchToNoneMode();
            this.seletedItem = undefined;
        }
        else if (value == CanvasMode.TextEditor) {
            this.setMarkMode(MarkMode.Text);
            this.operationMode = OperationMode.NoneMode;
            (_b = this.seletedItem) === null || _b === void 0 ? void 0 : _b.switchToNoneMode();
            this.seletedItem = undefined;
        }
        else if (value == CanvasMode.None) {
            this.setMarkMode(MarkMode.None);
            this.operationMode = OperationMode.OperationMode;
            if (this.seletedItem) {
                this.seletedItem.switchToOperationMode();
            }
        }
    }
    saveImage() {
        var _a;
        (_a = this.seletedItem) === null || _a === void 0 ? void 0 : _a.switchToNoneMode();
        return this.extractToBase64(this.stage);
    }
    saveImageNoXY() {
        var _a;
        (_a = this.seletedItem) === null || _a === void 0 ? void 0 : _a.switchToNoneMode();
        let groupX = this.chromosomeGroups.find((group) => { return group.id == 23; });
        let groupY = this.chromosomeGroups.find((group) => { return group.id == 24; });
        groupX.setItemShow(false);
        groupY.setItemShow(false);
        let dataurl = this.extractToBase64(this.stage);
        groupX.setItemShow(true);
        groupY.setItemShow(true);
        this.resetOperationMode();
        return dataurl;
    }
    resetOperationMode() {
        var _a, _b, _c;
        if (this.operationMode == OperationMode.OperationMode) {
            (_a = this.seletedItem) === null || _a === void 0 ? void 0 : _a.switchToOperationMode();
        }
        else if (this.operationMode == OperationMode.CentromereMode) {
            (_b = this.seletedItem) === null || _b === void 0 ? void 0 : _b.switchToCentromereMode();
        }
        else if (this.operationMode == OperationMode.EditMode) {
            (_c = this.seletedItem) === null || _c === void 0 ? void 0 : _c.switchToEditMode();
        }
    }
    focusWithItem(data) {
        if (!data) {
            return;
        }
        let group = this.chromosomeGroups.find((item) => { return item.id == data.id; });
        let item = group.getItem(data.index);
        this.stage.focusIn(group.toGlobal(item.position));
    }
    onItemSelected(item) {
        this.seletedItem = item;
        if (this.operationMode == OperationMode.NoneMode) {
            this.operationMode = OperationMode.OperationMode;
        }
        setTimeout((() => { this.resetOperationMode(); }).bind(this), 100);
        this.canvasMain.emit(SYSTEM_ITEM_SELECTED_IN_KARYOTYPE, item.data);
    }
    onItemMoveIn(groupId) {
        this.mouseInGroupId = groupId;
    }
    onItemMoveOut() {
        this.mouseInGroupId = -1;
    }
    onItemDragEnd() {
        let newId = this.mouseInGroupId;
        if (this.mouseInGroupId == -1) {
            newId = this.seletedItem.id;
        }
        console.log(newId);
        let group = this.chromosomeGroups.find((item) => { return item.id == newId; });
        group.setItem(this.seletedItem);
        for (let i = 0; i < this.chromosomeGroups.length; i++) {
            this.chromosomeGroups[i].resetItemIndex();
        }
        for (let i = 0; i < this.rowGroups.length; i++) {
            this.rowGroups[i].recoder(false);
        }
        this.changeWidth(false);
        this.mouseInGroupId = -1;
    }
    onItemRemoved(data) {
        var _a;
        let group = this.chromosomeGroups.find((item) => { return item.id == data.id; });
        let item = group.getItem(data.index);
        (_a = this.seletedItem) === null || _a === void 0 ? void 0 : _a.switchToNoneMode();
        this.seletedItem = undefined;
        group.removeItem(item);
        this.canvasMain.emit(SYSTEM_GROUP_CHANGED, data.id);
    }
    loadImgComplete() {
        this.loadCount--;
        if (this.loadCount == 0) {
            this.canvasMain.emit(SYSTEM_ITEM_LOAD_COMPLETE, undefined);
            setTimeout((() => {
                this.changeWidth(true);
                if (this._isSaveGroupImage) {
                    let addedChromosomeIds = this.addedChromosomeIds;
                    setTimeout((() => {
                        for (let i = 0; i < addedChromosomeIds.length; i++) {
                            this.canvasMain.emit(SYSTEM_GROUP_CHANGED, addedChromosomeIds[i]);
                        }
                    }).bind(this), 100);
                }
            }).bind(this), 100);
        }
    }
    getMarkStyleData() {
        return this.markEditorCanvas.getMarkSettingData();
    }
    setMarkStyleData(data) {
        this.markEditorCanvas.setMarkSettingData(data);
    }
    getGroupImage(groupId) {
        var _a;
        //console.trace("getGroupImage");
        let hideController = !this.seletedItem ? false : this.seletedItem.id == groupId ? true : false;
        if (hideController) {
            (_a = this.seletedItem) === null || _a === void 0 ? void 0 : _a.switchToNoneMode();
        }
        let group = this.chromosomeGroups.find((item) => { return item.id == groupId; });
        let value = group.getGroupImage();
        if (hideController) {
            this.resetOperationMode();
        }
        return value;
    }
}
KaryotypeCanvas.ids = [[1, 2, 3, -1, 4, 5], [6, 7, 8, 9, 10, 11, 12], [13, 14, 15, -1, 16, 17, 18], [19, 20, 21, 22, 23, 24, 25]];
export default KaryotypeCanvas;
export var OperationMode;
(function (OperationMode) {
    OperationMode[OperationMode["NoneMode"] = 0] = "NoneMode";
    OperationMode[OperationMode["CentromereMode"] = 1] = "CentromereMode";
    OperationMode[OperationMode["OperationMode"] = 2] = "OperationMode";
    OperationMode[OperationMode["EditMode"] = 3] = "EditMode";
})(OperationMode || (OperationMode = {}));
