import * as PIXI from "pixi.js";
import UtilMath from "../../UtilMath";
import { MarkMode } from "../../../../Canvas/MarkEditorCanvas";
export default class MarkArrow extends PIXI.Graphics {
    constructor(startPoint, endPoint) {
        super();
        this.sizeIndex = 0;
        this.sizeValueList = [0.8, 1.0, 1.2, 1.4];
        this.sizeValue = 1;
        this.arrowColor = 0xff0000;
        /** 三角形长 */
        this.triangleLen = 10;
        /** 三角形长 */
        this.triangleLen2 = 10;
        /** 三角形宽 */
        this.triangleWidth = 7;
        /** 三角形宽2 */
        this.triangleWidth2 = 4;
        /** 线宽 */
        this.lineWidth = 2;
        /** 选中点大小 */
        this.pointSize = 4;
        /** 选中点大小的平方 */
        this.pointSqrSize = this.pointSize * this.pointSize;
        this.selected = false;
        this.isMouseIn = false;
        this.isSelectdHead = false;
        this.isSelectdTail = false;
        this.drawScale = 1;
        this.onMousemove = (event) => {
            if (!this.isMouseIn) {
                return;
            }
            if (this.isSelectdHead) {
                let pos = this.toLocal(event.global);
                this.startPoint.x = pos.x;
                this.startPoint.y = pos.y;
                this.drawArrow();
                event.stopPropagation();
            }
            else if (this.isSelectdTail) {
                let pos = this.toLocal(event.global);
                this.endPoint.x = pos.x;
                this.endPoint.y = pos.y;
                this.drawArrow();
                event.stopPropagation();
            }
            else {
                let pos = this.toLocal(event.global);
                let delta = UtilMath.subtract(pos, this.lastMousePos);
                //this.lastMousePos = pos;
                this.startPoint.x = this.lastStartPointPos.x + delta.x;
                this.startPoint.y = this.lastStartPointPos.y + delta.y;
                this.endPoint.x = this.lastEndPointPos.x + delta.x;
                this.endPoint.y = this.lastEndPointPos.y + delta.y;
                //this.checkPosition();
                this.drawArrow();
                event.stopPropagation();
            }
        };
        this.onMouseup = (event) => {
            if (this.isMouseIn) {
                event.stopPropagation();
            }
            this.editorParent.off("mousemove", this.onMousemove);
            this.editorParent.off("mouseup", this.onMouseup);
            //this.off("mouseup", this.onMouseup);
            //this.parent.off("mouseout", this.onMouseup);
            this.isMouseIn = false;
            this.isSelectdHead = false;
            this.isSelectdTail = false;
            this.editorParent.operationEnd();
        };
        this.startPoint = new PIXI.Point(startPoint.x, startPoint.y);
        this.endPoint = new PIXI.Point(endPoint.x, endPoint.y);
        this.interactive = true;
        this.on("mousedown", this.onMousedown);
        this.on("mousemove", (event) => {
            let pos = this.toLocal(event.global);
            let downPoint = new PIXI.Point(pos.x, pos.y);
            if (UtilMath.sqrDistance(downPoint, this.startPoint) < this.pointSqrSize || UtilMath.sqrDistance(downPoint, this.endPoint) < this.pointSqrSize) {
                if (this.cursor != "ns-resize") {
                    this.cursor = "ns-resize";
                }
            }
            else {
                if (this.cursor != "move") {
                    this.cursor = "move";
                }
            }
        });
        this.drawArrow();
    }
    setStyle(type, data) {
        switch (type) {
            case "color":
                this.arrowColor = data;
                this.drawArrow();
                break;
            case "size":
                this.sizeIndex = data;
                this.sizeValue = this.sizeValueList[this.sizeIndex];
                this.drawArrow();
        }
    }
    getItemData() {
        //return { type: EditorMode.Text, data:{ text: this.textInput.text, fontSize:this.fontSize, color: this.fontColor } };
        return { type: MarkMode.Arrow, data: { size: this.sizeIndex, startPoint: { x: this.startPoint.x, y: this.startPoint.y }, endPoint: { x: this.endPoint.x, y: this.endPoint.y }, color: this.arrowColor } };
    }
    setItemData(obj) {
        let data = obj.data;
        this.startPoint = new PIXI.Point(data.startPoint.x, data.startPoint.y);
        this.endPoint = new PIXI.Point(data.endPoint.x, data.endPoint.y);
        this.arrowColor = data.color;
        if (!data.size) {
            data.size = 0;
        }
        this.sizeIndex = data.size;
        this.sizeValue = this.sizeValueList[this.sizeIndex];
        this.drawArrow();
    }
    init(editor) {
        this.editorParent = editor;
        this.editorParent.addChild(this);
    }
    setParent(editor) {
        this.editorParent = editor;
        this.editorParent.addChild(this);
    }
    setSelect(bo) {
        this.selected = bo;
        this.drawArrow();
        if (this.selected) {
            this.editorParent.setChildIndex(this, this.editorParent.children.length - 1);
        }
    }
    selectHead() {
        this.isSelectdHead = true;
        this.isSelectdTail = false;
    }
    selectTail() {
        this.isSelectdHead = false;
        this.isSelectdTail = true;
    }
    setMousedown() {
        this.isMouseIn = true;
        this.editorParent.off("mousemove", this.onMousemove);
        this.editorParent.off("mouseup", this.onMouseup);
        this.editorParent.on("mousemove", this.onMousemove);
        this.editorParent.on("mouseup", this.onMouseup);
        this.editorParent.setSelectItem(this);
    }
    onMousedown(event) {
        this.isMouseIn = true;
        this.editorParent.off("mousemove", this.onMousemove);
        this.editorParent.off("mouseup", this.onMouseup);
        //this.off("mouseup", this.onMouseup);
        //this.editorParent.off("mouseout", this.onMouseup);
        this.editorParent.on("mousemove", this.onMousemove);
        this.editorParent.on("mouseup", this.onMouseup);
        //this.on("mouseup", this.onMouseup);
        //this.editorParent.on("mouseout", this.onMouseup);
        event.stopPropagation();
        this.editorParent.setSelectItem(this);
        let downPoint = this.toLocal(event.global);
        this.lastStartPointPos = new PIXI.Point(this.startPoint.x, this.startPoint.y);
        this.lastEndPointPos = new PIXI.Point(this.endPoint.x, this.endPoint.y);
        this.lastMousePos = downPoint;
        if (UtilMath.sqrDistance(downPoint, this.startPoint) < this.pointSqrSize) {
            this.isSelectdHead = true;
        }
        else if (UtilMath.sqrDistance(downPoint, this.endPoint) < this.pointSqrSize) {
            this.isSelectdTail = true;
        }
        if (this.editorParent.getMode() != MarkMode.Arrow) {
            this.editorParent.antoSwitchMode(MarkMode.Arrow);
        }
        this.editorParent.operationStart();
    }
    checkPosition() {
        let te;
    }
    refresh() {
        this.drawArrow();
    }
    setScale(scale) {
        this.drawScale = scale;
        this.scale = new PIXI.Point(scale, scale);
    }
    setStartPoint(x, y) {
        this.startPoint.x = x;
        this.startPoint.y = y;
        this.drawArrow();
    }
    setEndPoint(x, y) {
        this.endPoint.x = x;
        this.endPoint.y = y;
        this.drawArrow();
    }
    drawArrow() {
        let v = UtilMath.subtract(this.endPoint, this.startPoint);
        let len = UtilMath.magnitude(v);
        let normalize = UtilMath.normalize(v);
        let vNormalize = new PIXI.Point(-normalize.y, normalize.x);
        let startPoint = this.startPoint;
        let endPoint = this.endPoint;
        let graphics = this;
        graphics.clear();
        let pointArr = new Array(4);
        let v1 = UtilMath.multiply(normalize, this.triangleLen * this.sizeValue);
        let v2 = UtilMath.multiply(vNormalize, this.triangleWidth * this.sizeValue);
        let v3 = UtilMath.multiply(normalize, this.triangleLen2 * this.sizeValue);
        let v4 = UtilMath.multiply(vNormalize, this.triangleWidth2 * this.sizeValue);
        pointArr[0] = UtilMath.add(startPoint, UtilMath.add(v1, v2));
        pointArr[1] = UtilMath.add(startPoint, UtilMath.add(v3, v4));
        if (len < this.triangleLen) {
            pointArr[2] = UtilMath.add(startPoint, UtilMath.multiply(normalize, this.triangleLen));
        }
        else {
            pointArr[2] = endPoint;
        }
        pointArr[3] = UtilMath.add(startPoint, UtilMath.add(v3, UtilMath.multiply(v4, -1)));
        pointArr[4] = UtilMath.add(startPoint, UtilMath.add(v1, UtilMath.multiply(v2, -1)));
        graphics.beginPath();
        graphics.moveTo(startPoint.x * this.drawScale, startPoint.y * this.drawScale);
        for (let i = 0; i < pointArr.length; i++) {
            graphics.lineTo(pointArr[i].x * this.drawScale, pointArr[i].y * this.drawScale);
        }
        graphics.closePath();
        graphics.fill(this.arrowColor);
        graphics.stroke(this.arrowColor);
        if (this.selected) {
            this.drawSelectPoint();
        }
    }
    drawSelectPoint() {
        let graphics = this;
        let radius = this.pointSize;
        let scale = this.globalScale.x;
        graphics.circle(this.startPoint.x, this.startPoint.y, radius / scale);
        graphics.circle(this.endPoint.x, this.endPoint.y, radius / scale);
        graphics.fill(0xffffff);
        graphics.stroke({ width: this.lineWidth / scale, color: 0xff0000 });
    }
    /** 在全局中的比例 */
    get globalScale() {
        let x = 1;
        let y = 1;
        let scale;
        let parent = this.parent;
        while (parent) {
            scale = parent.scale;
            x *= scale.x;
            y *= scale.y;
            parent = parent.parent;
        }
        return new PIXI.Point(x, y);
    }
}
