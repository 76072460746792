import * as PIXI from "pixi.js";
import FishContainer from "./FishContainer";
export default class ImageController extends FishContainer {
    constructor(parent, main) {
        super();
        this.main = main;
        parent.addChild(this);
        this.boundsArea = new PIXI.Rectangle(0, 0, parent.width, parent.height);
        this.image = new PIXI.Sprite();
        this.addChild(this.image);
        this.setInteractive(false);
        let isMouseIn = false;
        this.on("mousedown", (e) => {
            e.stopImmediatePropagation();
            isMouseIn = true;
            let localPos = this.toLocal(e.global);
            this.main.eraseOperate(localPos, true);
        });
        this.on("mousemove", (e) => {
            e.stopImmediatePropagation();
            if (!isMouseIn) {
                return;
            }
            let localPos = this.toLocal(e.global);
            this.main.eraseOperate(localPos, false);
        });
        this.on("mouseup", (e) => {
            isMouseIn = false;
        });
        this.on("mouseout", (e) => {
            isMouseIn = false;
        });
    }
    onResize(width, height) {
        //console.log("onresize: ", width, height, this.boundsArea.width, this.boundsArea.height, this.width, this.height);
    }
    setInteractive(value) {
        this.interactive = value;
        this.interactiveChildren = value;
    }
    loadTexture(image, callback) {
        let data = new Uint8Array(image.data);
        let texture = PIXI.Texture.from({ resource: data, width: image.width, height: image.height });
        if (this.image.texture) {
            this.image.texture.destroy(true);
        }
        this.image.texture = texture;
        if (this.boundsArea.width / this.boundsArea.height > texture.width / texture.height) {
            this.image.width = (texture.width / texture.height) * this.boundsArea.height;
            this.image.height = this.boundsArea.height;
            this.image.x = (this.boundsArea.width - this.image.width) / 2;
        }
        else {
            this.image.width = this.boundsArea.width;
            this.image.height = this.boundsArea.width / (texture.width / texture.height);
            this.image.y = (this.boundsArea.height - this.image.height) / 2;
        }
        if (callback) {
            callback();
        }
    }
}
