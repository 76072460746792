export class WindowGloablEvent {
    constructor() {
        this.eventMap = {};
    }
    static getSingleton() {
        if (!this.single) {
            this.single = new WindowGloablEvent;
        }
        return this.single;
    }
    addEventListener(type, listener, options) {
        let listenerList = this.eventMap[type];
        if (!listenerList) {
            listenerList = [];
            this.eventMap[type] = listenerList;
        }
        listenerList.push(listener);
        window.addEventListener(type, listener, options);
    }
    removeEventListener(type, listener, options) {
        let listenerList = this.eventMap[type];
        if (listenerList) {
            let index = listenerList.indexOf(listener);
            if (index >= 0) {
                listenerList.splice(index, 1);
            }
        }
        window.removeEventListener(type, listener, options);
    }
    removeAllEventListener(type) {
        if (type) {
            let listenerList = this.eventMap[type];
            if (listenerList) {
                for (let i = 0; i < listenerList.length; i++) {
                    this.removeEventListener(type, listenerList[i]);
                }
            }
            this.eventMap[type] = [];
        }
        else {
            for (let key in this.eventMap) {
                if (key) {
                    this.removeAllEventListener(key);
                }
            }
        }
    }
}
window.WindowGloablEvent = WindowGloablEvent;
