/**染色体边界数据 */
export default class BoundsData {
    constructor() {
        /**染色体编号 */
        this.id = 0;
        /**该编号的染色体条 */
        this.index = 0;
        /**变换角度 */
        this.rotateAngle = 0;
        /**着丝点 */
        this.centromere = 0.5;
        /**核型分析里的手动旋转角度 */
        this.angle = 0;
        /**核型分析里手动修改的Y轴坐标(百分比位置) */
        this.positionY = 1;
        /**原始点 */
        this.points = [];
        /**变换后的点 */
        this.transformedPoints = [];
        /**变换全部完成后的边界框 */
        this.bounds = { x: 0, y: 0, width: 0, height: 0 };
        /**旋转变换后移动变换前的边界框，用来确定变换后的点在最终图像上的位置 */
        this.afterBounds = { x: 0, y: 0, width: 0, height: 0 };
        /**图像dataurl */
        this.textureData = "";
    }
    copyTo(target) {
        target.id = this.id;
        target.index = this.index;
        target.rotateAngle = this.rotateAngle;
        target.centromere = this.centromere;
        target.angle = this.angle;
        target.positionY = this.positionY;
        target.points = [];
        for (let i = 0; i < this.points.length; i++) {
            target.points.push({ x: this.points[i].x, y: this.points[i].y });
        }
        target.transformedPoints = [];
        for (let i = 0; i < this.transformedPoints.length; i++) {
            target.transformedPoints.push({ x: this.transformedPoints[i].x, y: this.transformedPoints[i].y });
        }
        target.bounds = { x: this.bounds.x, y: this.bounds.y, width: this.bounds.width, height: this.bounds.height };
        target.afterBounds = { x: this.afterBounds.x, y: this.afterBounds.y, width: this.afterBounds.width, height: this.afterBounds.height };
        target.textureData = this.textureData;
        target.imageData = this.imageData;
    }
    copyFrom(source) {
        this.id = source.id;
        this.index = source.index;
        this.rotateAngle = isNaN(source.rotateAngle) ? 0 : source.rotateAngle;
        this.centromere = isNaN(source.centromere) ? 0.5 : source.centromere;
        this.angle = isNaN(source.angle) ? 0 : source.angle;
        this.positionY = isNaN(source.positionY) ? 1 : source.positionY;
        this.points = [];
        if (source.points) {
            for (let i = 0; i < source.points.length; i++) {
                this.points.push({ x: source.points[i].x, y: source.points[i].y });
            }
        }
        this.transformedPoints = [];
        if (source.transformedPoints) {
            for (let i = 0; i < source.transformedPoints.length; i++) {
                this.transformedPoints.push({ x: source.transformedPoints[i].x, y: source.transformedPoints[i].y });
            }
        }
        if (source.bounds) {
            this.bounds = { x: source.bounds.x, y: source.bounds.y, width: source.bounds.width, height: source.bounds.height };
        }
        if (source.afterBounds) {
            this.afterBounds = { x: source.afterBounds.x, y: source.afterBounds.y, width: source.afterBounds.width, height: source.afterBounds.height };
        }
        if (source.textureData) {
            this.textureData = source.textureData;
        }
        if (source.imageData) {
            this.imageData = source.imageData;
        }
    }
    clone() {
        let value = new BoundsData();
        value.copyFrom(this);
        return value;
    }
    serialize() {
        return JSON.stringify(this);
    }
    deserialize(value) {
        let data = JSON.parse(value);
        this.id = data.id;
        this.index = data.index;
        this.rotateAngle = data.rotateAngle;
        this.centromere = data.centromere;
        this.angle = data.angle;
        this.positionY = data.positionY;
        this.points = data.points;
        this.transformedPoints = data.transformedPoints;
        this.bounds = data.bounds;
        this.afterBounds = data.afterBounds;
        this.textureData = data.textureData;
    }
    static createFromString(value) {
        let target = new BoundsData();
        target.deserialize(value);
        return target;
    }
}
