import * as PIXI from "pixi.js";
import TipsLab from "./TipsLab";
export default class ToolbarBtn extends PIXI.Sprite {
    constructor(unactiveImg, activeImg, labelStr, callback) {
        super(unactiveImg);
        this._isActive = false;
        this.unactiveImg = unactiveImg;
        this.activeImg = activeImg;
        this.labelStr = labelStr;
        this.callback = callback;
        this.on("mouseenter", this.onMouseEnter, this);
        this.on("mouseout", this.onMouseOut, this);
        this.on("click", this.onClick, this);
        this.on("mousedown", (e) => {
            e.stopImmediatePropagation();
        });
        this.on("mouseup", (e) => {
            e.stopImmediatePropagation();
        });
        this.on("mousemove", (e) => {
            let pos = e.global;
            pos = this.parent.toLocal(pos);
            pos.x += 10;
            pos.y += 10;
            if (this.tipsLabel) {
                this.tipsLabel.position = pos;
            }
        });
    }
    get isActive() { return this._isActive; }
    set isActive(value) {
        this._isActive = value;
        if (value) {
            this.texture = this.activeImg;
        }
        else {
            this.texture = this.unactiveImg;
        }
    }
    onMouseEnter() {
        this.texture = this.activeImg;
        this.tipsLabel = new TipsLab(this.labelStr, 14);
        this.parent.addChild(this.tipsLabel);
    }
    onMouseOut() {
        var _a;
        if (this._isActive) {
            this.texture = this.activeImg;
        }
        else {
            this.texture = this.unactiveImg;
        }
        (_a = this.tipsLabel) === null || _a === void 0 ? void 0 : _a.destroy(true);
    }
    onClick() {
        this.callback();
    }
}
