import { Graphics, Point } from "pixi.js";
import UtilMath from "../UtilMath";
import Curve from "./Curve";
/** 曲线在 256 * 256 的方格内 */
export default class ColorCurve {
    static getDefaultData() {
        let keyPointList = [
            [new Point(0, 0), new Point(255, 255)],
            [new Point(0, 0), new Point(255, 255)],
            [new Point(0, 0), new Point(255, 255)],
            [new Point(0, 0), new Point(255, 255)]
        ];
        return JSON.stringify(keyPointList);
    }
    constructor(width = 256, height = 256) {
        this._graphic = new Graphics();
        this.mode = ColorCurveMode.RGB;
        /** 最大X值 */
        this.maxX = 255;
        /** 最小X值 */
        this.minX = 0;
        /** 最大Y值 */
        this.maxY = 255;
        /** 最小Y值 */
        this.minY = 0;
        /** 显示宽度 */
        this.width = 256;
        /** 显示高度 */
        this.height = 256;
        /** 采样宽度 */
        this.defaultWidth = 256;
        /** 采样高度 */
        this.defaultHeight = 256;
        /** 背景颜色 */
        this.bgColor = 0x000000;
        /** 点的可点击范围 */
        this.pointClickSize = 12;
        this.isMouseUp = false;
        this.mouseDownPos = new Point();
        this._selectPointIndex = -1;
        this._selectPointPos = new Point();
        //#endregion
        this.isDataChange = false;
        this._removePointIndex = -1;
        //this.pointArr = [new Point(startPoint.x, startPoint.y), new Point(endPoint.x, endPoint.y)];
        this.width = width;
        this.height = height;
        //this.sort();
        this._graphic.x = 20;
        this._graphic.y = 20;
        this._graphic.rect(0, 0, width, height);
        this._graphic.fill({ color: 0xff0000, alpha: 0 });
        this._rgbCurve = new Curve();
        this._rCurve = new Curve();
        this._gCurve = new Curve();
        this._bCurve = new Curve();
        this._rgbCurve.lineColor = 0x000000;
        this._rgbCurve.pointColor = 0x000000;
        this._rCurve.lineColor = 0xff0000;
        this._rCurve.pointColor = 0xff0000;
        this._gCurve.lineColor = 0x00ff00;
        this._gCurve.pointColor = 0x00ff00;
        this._bCurve.lineColor = 0x0000ff;
        this._bCurve.pointColor = 0x0000ff;
        this.setMode(ColorCurveMode.RGB);
        // window.addEventListener("keydown", (event) => {
        //     console.log(event.key);
        //     switch (event.key) {
        //         case "1":
        //             this.setMode(ColorCurveMode.RGB);
        //             break;
        //         case "2":
        //             this.setMode(ColorCurveMode.R);
        //             break;
        //         case "3":
        //             this.setMode(ColorCurveMode.G);
        //             break;
        //         case "4":
        //             this.setMode(ColorCurveMode.B);
        //             break;
        //     }
        // });
    }
    AddEvent() {
        this._graphic.interactive = true;
        //this._graphic.parent.interactive = true;
        this._graphic.on("mousedown", this.onMouseDown.bind(this));
        this._graphic.on("mousemove", this.onMouseMove.bind(this));
        this._graphic.on("mouseup", this.onMouseUp.bind(this));
        this._graphic.on("mouseout", this.onMouseUp.bind(this));
    }
    on(event, fn, context) {
        this._graphic.on(event, fn, context);
    }
    off(event, fn, context, once) {
        this._graphic.off(event, fn, context, once);
    }
    get graphic() {
        return this._graphic;
    }
    get currCurve() {
        return this.editorCurve;
    }
    get rgbCurve() {
        return this._rgbCurve;
    }
    get rCurve() {
        return this._rCurve;
    }
    get gCurve() {
        return this._gCurve;
    }
    get bCurve() {
        return this._bCurve;
    }
    getRGBCurve() {
        return this._rgbCurve.pointMapY();
    }
    getRCurve() {
        return this._rCurve.pointMapY();
    }
    getGCurve() {
        return this._gCurve.pointMapY();
    }
    getBCurve() {
        return this._bCurve.pointMapY();
    }
    get xScale() {
        return this.width / this.defaultWidth;
    }
    get yScale() {
        return this.height / this.defaultHeight;
    }
    //#region 关键点设置
    setMode(mode) {
        this.mode = mode;
        switch (mode) {
            case ColorCurveMode.RGB:
                this.editorCurve = this._rgbCurve;
                break;
            case ColorCurveMode.R:
                this.editorCurve = this._rCurve;
                break;
            case ColorCurveMode.G:
                this.editorCurve = this._gCurve;
                break;
            case ColorCurveMode.B:
                this.editorCurve = this._bCurve;
                break;
        }
        this._graphic.emit("modeChange", this);
        this.drawCanvas();
    }
    setPointSize(pointSize) {
        this._rgbCurve.pointSize = pointSize;
        this._rCurve.pointSize = pointSize;
        this._gCurve.pointSize = pointSize;
        this._bCurve.pointSize = pointSize;
    }
    setSize(width, height) {
        this.width = width;
        this.height = height;
    }
    setArea(rect) {
        this.graphic.hitArea = rect;
    }
    /** 添加关键节点 */
    addKeyPoints(points) {
        this.editorCurve.addKeyPoints(points);
        this.curveChange();
    }
    /** 添加关键节点 */
    addKeyPoint(...points) {
        this.addKeyPoints(points);
    }
    /** 移除关键节点 */
    removeKeyPoint(index) {
        this.editorCurve.removeKeyPoint(index);
    }
    /** 测试点击到的点,返回关键点的序号 */
    tryClickPoint(x, y) {
        //y = this.defaultHeight - y;
        let xScalar = this.xScale;
        let yScalar = this.yScale;
        let clickPoint = new Point(x / xScalar, y / yScalar);
        let sqrDis = Number.MAX_VALUE;
        let selectIndex = -1;
        let selectRange = this.pointClickSize;
        //console.log(clickPoint, this.pointArr);
        for (let i = 0; i < this.editorCurve.keyPointCount; i++) {
            let point = this.editorCurve.getKeyPoint(i);
            //console.log(clickPoint, point);
            let sqrD = UtilMath.sqrDistance(clickPoint, point);
            if (sqrD <= selectRange && sqrD < sqrDis) {
                sqrDis = sqrD;
                selectIndex = i;
            }
        }
        return selectIndex;
    }
    //#region 移动点
    onMouseDown(event) {
        this.isMouseUp = true;
        this.isDataChange = false;
        this._removePointIndex = -1;
        let point = this._graphic.toLocal(event.global);
        this.mouseDownPos = new Point(point.x, this.height - point.y);
        this._selectPointIndex = this.tryClickPoint(this.mouseDownPos.x, this.mouseDownPos.y);
        this.beforeData = JSON.stringify(this.getCurveData());
        //console.log(this._selectPointIndex);
        if (this._selectPointIndex >= 0) {
            let point = this.editorCurve.getKeyPoint(this._selectPointIndex);
            this._selectPointPos = new Point(point.x, point.y);
            // this.drawCanvas();
            // return;
        }
        else {
            let x = this.mouseDownPos.x / this.xScale;
            let y = this.mouseDownPos.y / this.yScale;
            let cY = this.editorCurve.getY(x);
            if (y > cY - 10 && y < cY + 10) {
                this._selectPointIndex = this.editorCurve.addKeySingePoint(new Point(x, y));
                if (this._selectPointIndex >= 0) {
                    let point = this.editorCurve.getKeyPoint(this._selectPointIndex);
                    this._selectPointPos = new Point(point.x, point.y);
                    this.isDataChange = true;
                }
            }
        }
        //console.log("选中点", this._selectPointIndex);
        //console.log("选中点", this._selectPointPos);
        this.drawCanvas();
    }
    onMouseMove(event) {
        if (this.isMouseUp) {
            if (this._selectPointIndex >= 0) {
                let point = this._graphic.toLocal(event.global);
                point = new Point(point.x, this.height - point.y);
                point = UtilMath.subtract(point, this.mouseDownPos);
                point.x /= this.xScale;
                point.y /= this.yScale;
                point = UtilMath.add(this._selectPointPos, point);
                if (!this.editorCurve.movePoint(point.x, point.y, this._selectPointIndex)) {
                    this._removePointIndex = this._selectPointIndex;
                    this._selectPointIndex = -1;
                }
                this.isDataChange = true;
            }
            else if (this._removePointIndex >= 0) {
                let point = this._graphic.toLocal(event.global);
                point = new Point(point.x, this.height - point.y);
                point = UtilMath.subtract(point, this.mouseDownPos);
                point.x /= this.xScale;
                point.y /= this.yScale;
                point = UtilMath.add(this._selectPointPos, point);
                this.moveAddPoint(point.x, point.y);
                this.isDataChange = true;
            }
            else {
                return;
            }
            this.curveChange();
        }
    }
    onMouseUp(event) {
        this.isMouseUp = false;
        if (this.isDataChange) {
            this.isDataChange = false;
            this._graphic.emit("editorEnd", this.beforeData, this.getCurveData());
        }
    }
    /** 移动还原删除点 */
    moveAddPoint(x, y) {
        if (this._removePointIndex < 0) {
            return;
        }
        let index = this._removePointIndex;
        let nextPoint = this.editorCurve.getKeyPoint(index);
        let prevPoint = this.editorCurve.getKeyPoint(index - 1);
        let nextX = nextPoint.x;
        let prevX = prevPoint.x;
        if ((x <= nextX - 1 && x >= prevX + 1) && (y <= this.maxY && y >= this.minY)) {
            this._selectPointIndex = this.editorCurve.addKeySingePoint(new Point(x, y));
            if (this._selectPointIndex >= 0) {
                this._removePointIndex = -1;
            }
        }
    }
    //#endregion
    //#region 绘制曲线
    /** 设置bg在颜色 */
    setBgColor(color) {
        this.bgColor = color;
        this.drawCanvas();
    }
    curveChange() {
        this._graphic.emit("curveChange", this);
        //console.log(this.pointMapY);
        this.drawCanvas();
    }
    /** 绘制Canvas */
    drawCanvas() {
        //this.drawBG();
        this.graphic.clear();
        if (this.mode == ColorCurveMode.RGB) {
            let curveList = [this._rCurve, this._gCurve, this._bCurve];
            for (let i = 0; i < curveList.length; i++) {
                let curve = curveList[i];
                if (!curve.isDefault) {
                    this.drawCurve(curve);
                }
            }
        }
        this.drawCurve(this.editorCurve);
        this.drawPoint(this.editorCurve);
    }
    // /** 绘制背景 */
    // private drawBG() {
    //     this._graphic.rect(-20, -20, this.width + 40, this.height + 40);
    //     this._graphic.fill(this.bgColor);
    // }
    /** 绘制曲线 */
    drawCurve(curve) {
        let yScale = this.yScale;
        let xScale = this.xScale;
        this._graphic.moveTo(0, (this.defaultHeight - curve.getY(0)) * yScale);
        for (let i = 1; i < this.defaultWidth; i++) {
            this._graphic.lineTo(i * xScale, (this.defaultHeight - curve.getY(i)) * yScale);
        }
        this._graphic.lineTo(this.maxX * xScale, (this.defaultHeight - curve.getY(this.maxX)) * yScale);
        this._graphic.stroke(curve.lineColor);
    }
    /** 绘制点 */
    drawPoint(curve) {
        let pointHelfSize = curve.pointSize / 2;
        for (let i = 0; i < curve.keyPointCount; i++) {
            let point = curve.getKeyPoint(i);
            this._graphic.rect(point.x * this.xScale - pointHelfSize, (this.defaultHeight - point.y) * this.yScale - pointHelfSize, curve.pointSize, curve.pointSize);
            this._graphic.stroke(curve.pointColor);
            if (i == this._selectPointIndex) {
                this._graphic.fill(curve.pointColor);
            }
        }
    }
    //#endregion
    setCurveData(json) {
        let data = json;
        let curveList = [this.rgbCurve, this.rCurve, this.gCurve, this.bCurve];
        for (let i = 0; i < curveList.length; i++) {
            let curve = curveList[i];
            this.setCurveKeyPoint(curve, data[i]);
        }
        this.curveChange();
        //this._graphic.emit("curveChange", this);
    }
    resetCurveData() {
        let curveList = [this.rgbCurve, this.rCurve, this.gCurve, this.bCurve];
        for (let i = 0; i < curveList.length; i++) {
            let curve = curveList[i];
            curve.resetCurveData();
        }
        this.curveChange();
    }
    getCurveData() {
        let curveList = [this.rgbCurve, this.rCurve, this.gCurve, this.bCurve];
        let keyPointList = [];
        for (let i = 0; i < curveList.length; i++) {
            let curve = curveList[i];
            keyPointList.push(this.getCurveKeyPoint(curve));
        }
        return keyPointList;
    }
    getCurveKeyPoint(curve) {
        let count = curve.keyPointCount;
        let keyPoint = [];
        for (let i = 0; i < count; i++) {
            keyPoint.push(curve.getKeyPoint(i));
        }
        return keyPoint;
    }
    setCurveKeyPoint(curve, data) {
        curve.setAllKeyPoint(data);
    }
}
export var ColorCurveMode;
(function (ColorCurveMode) {
    ColorCurveMode[ColorCurveMode["RGB"] = 0] = "RGB";
    ColorCurveMode[ColorCurveMode["R"] = 1] = "R";
    ColorCurveMode[ColorCurveMode["G"] = 2] = "G";
    ColorCurveMode[ColorCurveMode["B"] = 3] = "B";
})(ColorCurveMode || (ColorCurveMode = {}));
