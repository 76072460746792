//import * as PIXI from "pixi.js";
import { Point, Polygon } from "pixi.js";
export default class OutlineTool {
    static get polybool() {
        if (!OutlineTool._polybool) {
            OutlineTool._polybool = require("polybooljs");
        }
        return OutlineTool._polybool;
    }
    /** 并集,区域相加算法 */
    static union(data1, inverted1, data2, inverted2) {
        let orgData = OutlineTool.union2(OutlineTool.pointArrToNumberArrTwo(data1), inverted1, OutlineTool.pointArrToNumberArrTwo(data2), inverted2);
        let data = { points: OutlineTool.numberArrToPointArrTwo(orgData.regions), inverted: orgData.inverted };
        return data;
    }
    static union2(pointArr1, inverted1, pointArr2, inverted2) {
        let pointArr;
        pointArr = OutlineTool.polybool.union({
            regions: pointArr1,
            inverted: inverted1
        }, {
            regions: pointArr2,
            inverted: inverted2
        });
        return pointArr;
    }
    /** 差集,区域相减算法 */
    static difference(data1, inverted1, data2, inverted2) {
        let orgData = OutlineTool.difference2(OutlineTool.pointArrToNumberArrTwo(data1), inverted1, OutlineTool.pointArrToNumberArrTwo(data2), inverted2);
        let data = { points: OutlineTool.numberArrToPointArrTwo(orgData.regions), inverted: orgData.inverted };
        return data;
    }
    static difference2(pointArr1, inverted1, pointArr2, inverted2) {
        let pointArr;
        pointArr = OutlineTool.polybool.difference({
            regions: pointArr1,
            inverted: inverted1
        }, {
            regions: pointArr2,
            inverted: inverted2
        });
        return pointArr;
    }
    /** 1维点数组转2维数字数组 */
    static pointArrToNumberArr(pointArr) {
        let numberArr = [];
        for (let i = 0; i < pointArr.length; i++) {
            numberArr.push([pointArr[i].x, pointArr[i].y]);
        }
        return numberArr;
    }
    /** 2维点数组转3维数字数组 */
    static pointArrToNumberArrTwo(pointArr) {
        let numberArr = [];
        for (let i = 0; i < pointArr.length; i++) {
            numberArr.push(OutlineTool.pointArrToNumberArr(pointArr[i]));
        }
        return numberArr;
    }
    /** 2维数字数组转1维点数组 */
    static numberArrToPointArr(numberArr) {
        let pointArr = [];
        for (let i = 0; i < numberArr.length; i++) {
            pointArr.push(new Point(numberArr[i][0], numberArr[i][1]));
        }
        return pointArr;
    }
    /** 3维数字数组转2维点数组 */
    static numberArrToPointArrTwo(numberArr) {
        let pointArr = [];
        for (let i = 0; i < numberArr.length; i++) {
            pointArr.push(OutlineTool.numberArrToPointArr(numberArr[i]));
        }
        return pointArr;
    }
    /** 包含在内部 */
    static contains(points, point) {
        let polybool = new Polygon(points);
        if (polybool.contains(point.x, point.y)) {
            return true;
        }
        return false;
    }
}
