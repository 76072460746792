/** 操作记录 */
export const SYSTEM_OPERATION_STEP_PUSH = "SYSTEM_OPERATION_STEP_PUSH"; //操作记录
/** 操作回溯 */
export const SYSTEM_OPERATION_STEP_UNDO = "SYSTEM_OPERATION_STEP_UNDO"; //操作回溯
/** 操作重放 */
export const SYSTEM_OPERATION_STEP_REDO = "SYSTEM_OPERATION_STEP_REDO"; //操作重放
/** 窗口大小改变 */
export const SYSTEM_WINDOW_RESIZE = "SYSTEM_WINDOW_RESIZE"; //窗口大小改变
/** 切换操作窗口 */
export const SYSTEM_CHANGE_OPERATION_WINDOW = "SYSTEM_CHANGE_OPERATION_WINDOW"; //切换操作窗口
/** 染色体数量变更 */
export const SYSTEM_CHANGE_CHROMOSOME_COUNT = "SYSTEM_CHANGE_CHROMOSOME_COUNT"; //染色体数量变更
/** 画布加载完成 */
export const SYSTEM_CANVAS_LOAD_COMPLETE = "SYSTEM_CANVAS_LOAD_COMPLETE"; //画布加载完成
/** 图片加载完成 */
export const SYSTEM_IMAGE_LOAD_COMPLETE = "SYSTEM_IMAGE_LOAD_COMPLETE"; //图片加载完成
/** 核型加载完成 */
export const SYSTEM_KARYOTYPE_LOAD_COMPLETE = "SYSTEM_KARYOTYPE_LOAD_COMPLETE"; //核型加载完成
/** 需要保存 */
export const SYSTEM_NEED_SAVE = "SYSTEM_NEED_SAVE"; //需要保存
/** 用户配置保存 */
export const SYSTEM_USER_CONFIG_SAVE = "SYSTEM_USER_CONFIG_SAVE"; //需要保存
/** 染色体被移除 */
export const SYSTEM_ITEM_REMOVED = "SYSTEM_ITEM_REMOVED"; //染色体被移除
/** 染色体被添加 */
export const SYSTEM_ITEM_ADDED = "SYSTEM_ITEM_ADDED"; //染色体被添加
/** 核型分析页面选中染色体 */
export const SYSTEM_ITEM_SELECTED_IN_KARYOTYPE = "SYSTEM_ITEM_SELECTED_IN_KARYOTYPE"; //核型分析页面选中染色体
/** 原图页面选中染色体 */
export const SYSTEM_ITEM_SELECTED_IN_ORIGINAL_IMAGE = "SYSTEM_ITEM_SELECTED_IN_ORIGINAL_IMAGE"; //原图页面选中染色体
/** 标记设置变了 */
export const MARK_STYLE_CHANGED = "MARK_STYLE_CHANGED"; //原图页面选中染色体
/** 核型图染色体对象加载完毕 */
export const SYSTEM_ITEM_LOAD_COMPLETE = "SYSTEM_ITEM_LOAD_COMPLETE"; //核型图染色体对象加载完毕
/**染色体组变更 */
export const SYSTEM_GROUP_CHANGED = "SYSTEM_GROUP_CHANGED"; //染色体组变更
