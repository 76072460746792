import * as PIXI from "pixi.js";
export default class StyleEditorBox extends PIXI.Container {
    constructor() {
        super();
        this.itemList = [];
    }
    setItem(args) {
        for (let i = 0; i < this.itemList.length; i++) {
            this.removeChild(this.itemList[i]);
            this.itemList[i].onStyleChanged(undefined);
        }
        this.itemList = args;
        let x = 0;
        for (let i = 0; i < this.itemList.length; i++) {
            this.addChild(this.itemList[i]);
            this.itemList[i].onStyleChanged(this.onStyleChanged.bind(this));
            this.itemList[i].x = x;
            x += this.itemList[i].width;
        }
        this.boundsArea = new PIXI.Rectangle(0, 0, x, 40);
        //this.hitArea = new PIXI.Rectangle(0, 0, x, 40);
        this.interactive = true;
    }
    getStyleData() {
        let data = {};
        for (let i = 0; i < this.itemList.length; i++) {
            data = Object.assign(data, this.itemList[i].getSytleData());
        }
        return data;
    }
    setStyleChangedEvent(func) {
        this.styleChangedFunc = func;
    }
    onStyleChanged(type, data) {
        if (this.styleChangedFunc) {
            this.styleChangedFunc(type, data);
        }
    }
}
