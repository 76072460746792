import { Point } from "pixi.js";
import MarkArrow from "./MarkArrow";
import UtilMath from "../../UtilMath";
import ColorSelectBox from "../ColorSelectBox";
import SizeSelectBox from "../SizeSelectBox";
export default class MarkArrowEditor {
    constructor() {
        this.drawScale = 1;
        this.width = 0;
        this.height = 0;
    }
    setParent(parent) {
        this.parent = parent;
    }
    resize(w, h) {
    }
    getStyleItem() {
        if (!this.styleItem) {
            this.styleItem = [new SizeSelectBox(), new ColorSelectBox()];
        }
        if (this.styleData) {
            for (let i = 0; i < this.styleItem.length; i++) {
                for (let j = 0; j < this.styleData.length; j++) {
                    this.styleItem[i].setSaveStyleData(this.styleData[j]);
                }
            }
        }
        return this.styleItem;
    }
    createItem(obj) {
        let data = obj.data;
        let startPoint = new Point(data.startPoint.x, data.startPoint.y);
        let endPoint = new Point(data.endPoint.x, data.endPoint.y);
        let arrow = new MarkArrow(startPoint, endPoint);
        arrow.editor = this;
        this.parent.addItem(arrow);
        arrow.setItemData(obj);
    }
    onMousedown(event) {
        if (this.parent.selectIndex >= 0) {
            this.parent.setSelectItem(null);
        }
        event.stopPropagation();
        this.downPoint = new Point(event.globalX, event.globalY);
        this.parent.operationStart();
    }
    onMousemove(event) {
        if (this.downPoint && UtilMath.sqrDistance(new Point(event.globalX, event.globalX), this.downPoint) > 100) {
            event.stopPropagation();
            let startPoint;
            let endPoint;
            let parent = this.parent;
            endPoint = parent.toLocal(event.global);
            startPoint = parent.toLocal(this.downPoint);
            let arrow = new MarkArrow(startPoint, endPoint);
            arrow.editor = this;
            this.parent.addItem(arrow);
            arrow.setMousedown();
            arrow.selectHead();
            this.downPoint = undefined;
        }
    }
    onMouseup(event) {
        this.downPoint = undefined;
        //this.parent!.operationEnd();
    }
    drawCanvas() {
    }
    getSettingData() {
        let data = { type: "arrow", style: [] };
        if (this.styleItem) {
            for (let i = 0; i < this.styleItem.length; i++) {
                let item = this.styleItem[i];
                data.style.push(item.getSaveStyleData());
            }
        }
        return data;
    }
    setSettingData(data) {
        if (data.type != "arrow") {
            return;
        }
        this.styleData = data.style;
    }
}
