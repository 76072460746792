import * as PIXI from "pixi.js";
import FishScalePanel from "../Components/FishScalePanel";
import FishContainer from "../Components/FishContainer";
export default class PIXIFishCanvas {
    constructor(main, container, callback, failCallback, caller) {
        this.colorizeColor = 0x000000;
        this.colorizeAlpha = 0;
        this._pixi = new PIXI.Application();
        this._pixi.init({ preference: "webgl", powerPreference: "high-performance", antialias: false, resolution: 2, resizeTo: container, backgroundColor: 0x000000 }).then(() => {
            container.appendChild(this._pixi.canvas);
            console.log("PIXICanvas create complete");
            this._pixi.canvas.style.width = "100%";
            this._pixi.canvas.style.height = "100%";
            this._pixi.canvas.addEventListener("contextmenu", (e) => { e.preventDefault(); e.stopPropagation(); });
            this._pixi.canvas.addEventListener("mousedown", (() => { this._pixi.ticker.maxFPS = 60; }).bind(this));
            this._pixi.canvas.addEventListener("mouseup", (() => { this._pixi.ticker.maxFPS = 1; }).bind(this));
            this._pixi.canvas.addEventListener("mouseout", ((e) => { this._pixi.ticker.maxFPS = 1; }).bind(this));
            this._pixi.stage.boundsArea = new PIXI.Rectangle(0, 0, this._pixi.screen.width, this._pixi.screen.height);
            this.scalePanel = new FishScalePanel(main, this, this._pixi.stage.boundsArea);
            this.scalePanel.interactive = true;
            this.scalePanel.interactiveChildren = true;
            this._pixi.stage.addChild(this.scalePanel);
            this._stage = new FishContainer();
            this.scalePanel.addChild(this._stage);
            this.stage.boundsArea = this._pixi.stage.boundsArea;
            this.colorize = new PIXI.Graphics();
            this.pixi.stage.addChild(this.colorize);
            this.colorize.boundsArea = this._pixi.stage.boundsArea;
            this.changeColorize(this.colorizeColor, this.colorizeAlpha);
            callback === null || callback === void 0 ? void 0 : callback.call(caller);
            this._pixi.ticker.maxFPS = 1;
        }).catch((e) => {
            console.error("PIXICanvas create error: ", e);
            failCallback === null || failCallback === void 0 ? void 0 : failCallback.call(caller, e);
        });
    }
    get stage() { return this._stage; }
    get pixi() { return this._pixi; }
    get canControl() { return this.scalePanel.canControl; }
    set canControl(value) { this.scalePanel.canControl = value; }
    get scale() { return this.scalePanel.scale.x; }
    resize() {
        var _a;
        this._pixi.resize();
        this._pixi.stage.boundsArea = new PIXI.Rectangle(0, 0, this._pixi.screen.width, this._pixi.screen.height);
        (_a = this.scalePanel) === null || _a === void 0 ? void 0 : _a.resize();
        this.changeColorize(this.colorizeColor, this.colorizeAlpha);
    }
    extractToBase64(target, type) {
        var _a;
        let texture = this._pixi.renderer.extract.texture(target);
        let canvas = this._pixi.renderer.extract.canvas(texture);
        let dataurl = canvas.toDataURL(type, 1);
        (_a = canvas.getContext("2d")) === null || _a === void 0 ? void 0 : _a.clearRect(0, 0, canvas.width, canvas.height);
        canvas.width = 0;
        canvas.height = 0;
        texture.destroy(true);
        return dataurl;
    }
    changeColorize(color, alpha) {
        this.colorize.clear();
        this.colorize.rect(0, 0, this.pixi.screen.width, this.pixi.screen.height).fill({ color: color, alpha: alpha });
        this.colorize.stroke();
    }
}
