import * as PIXI from "pixi.js";
import StyleEditor from "./StyleEditor";
export default class SizeSelectBox extends StyleEditor {
    get selectSize() {
        return this.sizeArr[this.selectSizeIndex];
    }
    //protected colorBlockWidth: number = 100;
    //protected colorBlockHeight: number = 100;
    //protected colorBlockInfo!: ColorBlockInfo;
    constructor() {
        super();
        this.bg = new PIXI.Graphics();
        this.bgColor = 0xF2F6FD;
        this.selectBlock = new PIXI.Graphics();
        this.color = 0xFB3838;
        this.blockBg = 0xFFFFFF;
        this.pointColor = 0x2F8DDD;
        this.sizeArr = [0, 1, 2, 3];
        this.sizeRadiusList = [1, 2, 3, 4];
        this.sizeBlockList = [];
        this.sizeBlockWidth = 24;
        this.sizeBlockHeight = 24;
        this.selectSizeIndex = 0;
        this.init();
    }
    init() {
        this.addChild(this.bg);
        this.addChild(this.selectBlock);
        this.sizeBlockList = new Array(this.sizeRadiusList.length);
        //this.drawSelectBlock(0xFB3838);
        let x = 5;
        let y = (40 - this.sizeBlockHeight) / 2;
        for (let i = 0; i < this.sizeBlockList.length; i++) {
            let index = i;
            let graphic = new PIXI.Graphics();
            this.sizeBlockList[i] = graphic;
            this.addChild(this.sizeBlockList[i]);
            graphic.x = x;
            graphic.y = y;
            x += this.sizeBlockWidth + 3;
            this.drawSizeBlock(index, false, index == this.selectSizeIndex);
            this.sizeBlockList[i].interactive = true;
            this.sizeBlockList[i].hitArea = new PIXI.Rectangle(0, 0, this.sizeBlockWidth, this.sizeBlockHeight);
            this.sizeBlockList[i].on("mouseenter", () => { this.onMouseEnter(index); });
            this.sizeBlockList[i].on("mouseout", () => { this.onMouseOut(index); });
            this.sizeBlockList[i].on("click", () => { this.onClick(index); });
        }
        this.boundsArea = new PIXI.Rectangle(0, 0, x, 40);
        this.bg.rect(0, 0, x, 40);
        this.bg.fill({ color: this.bgColor });
        this.bg.hitArea = new PIXI.Rectangle(0, 0, x, 40);
        this.bg.interactive = true;
    }
    onMouseEnter(index) {
        if (index != this.selectSizeIndex) {
            this.drawSizeBlock(index, true, false);
        }
    }
    onMouseOut(index) {
        if (index != this.selectSizeIndex) {
            this.drawSizeBlock(index, false, false);
        }
    }
    onClick(index) {
        this.drawSizeBlock(this.selectSizeIndex, false, false);
        this.selectSizeIndex = index;
        this.drawSizeBlock(index, true, true);
        if (this.styleChangedFunc) {
            this.styleChangedFunc("size", this.sizeArr[index]);
        }
    }
    drawSizeBlock(index, isSuspension, isSelect) {
        let graphic = this.sizeBlockList[index];
        graphic.clear();
        let selectColor = 0xDBDFE5;
        let suspensionColor = 0xE7EBF2;
        let bgColor = isSelect ? selectColor : suspensionColor;
        let stroke = 0x9D9FA0;
        if (isSelect || isSuspension) {
            graphic.roundRect(0, 0, this.sizeBlockWidth, this.sizeBlockHeight, 1);
            graphic.stroke({ color: stroke, width: 1 });
            graphic.fill({ color: bgColor });
        }
        let x = this.sizeBlockWidth / 2;
        let y = this.sizeBlockHeight / 2;
        graphic.circle(x, y, this.sizeRadiusList[index]);
        graphic.fill(this.pointColor);
    }
    getSytleData() {
        return { size: this.sizeArr[this.selectSizeIndex] };
    }
    getSaveStyleData() {
        return { type: "SizeSelect", data: this.getSytleData() };
    }
    setSaveStyleData(data) {
        if (data.type != "SizeSelect") {
            return;
        }
        let size = data.data.size;
        let index = 0;
        for (let i = 0; i < this.sizeArr.length; i++) {
            if (this.sizeArr[i] == size) {
                index = i;
                break;
            }
        }
        this.drawSizeBlock(this.selectSizeIndex, false, false);
        this.selectSizeIndex = index;
        this.drawSizeBlock(index, true, true);
        //if(this.styleChangedFunc){ this.styleChangedFunc("fontSize", this.fontSize); }
    }
}
